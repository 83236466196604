/**
 * Never import anything from other project src files here.
 * The reason: these values are imported elsewhere and
 * as these values are calculated during module import
 * there will be race conditions regarding import order
 * if you import other modules here from within the project
 */

/**
 * @returns true if in development environment
 */
export function isDevEnv() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}
export function isStagingEnv() {
  return process.env.REACT_APP_ENV === 'staging'
}
export const DEFAULT_LANGUAGE = getEnvVar('REACT_APP_DEFAULT_LANGUAGE')
export const ROUTE_BASE_PATH = getEnvVar('REACT_APP_ROUTE_BASE_PATH')
export const APP_VERSION = getEnvVar('REACT_APP_VERSION')
export const SUPPORTED_LANGUAGES = getJsonArrayEnvVar(
  'REACT_APP_SUPPORTED_LANGUAGES'
)
export const COMPANY_AREA_COUNTS = getJsonNumberArrayEnvVar(
  'REACT_APP_COMPANY_AREA_COUNTS'
)
export const PUBLIC_AREA_COUNTS = getJsonNumberArrayEnvVar(
  'REACT_APP_PUBLIC_AREA_COUNTS'
)
export const FOODSERVICES_AREA_COUNTS = getJsonNumberArrayEnvVar(
  'REACT_APP_FOODSERVICES_AREA_COUNTS'
)
export const ORGANIZATIONS_AREA_COUNTS = getJsonNumberArrayEnvVar(
  'REACT_APP_ORGANIZATIONS_AREA_COUNTS'
)

function getJsonArrayEnvVar(name: string): string[] {
  const strVal = getEnvVar(name)
  try {
    const val = JSON.parse(strVal)
    if (!Array.isArray(val)) {
      throw new Error(`Must be a JSON array ie. ["val1", "val2"])`)
    }
    Object.values(val).forEach((v) => {
      if (typeof v !== 'string' && !(v instanceof String)) {
        throw new Error(
          `Must be a JSON array with string items only. Correct: ["val1","val2"], wrong: [1, 2].`
        )
      }
    })
    return val as string[]
  } catch (err) {
    throw new Error(
      `An error occured while trying to parse JSON array environment variable ${name}: ${err}`
    )
  }
}
function getJsonNumberArrayEnvVar(name: string): number[] {
  const strVal = getEnvVar(name)
  try {
    const val = JSON.parse(strVal)
    if (!Array.isArray(val)) {
      throw new Error(`Must be a JSON array ie. [1,2])`)
    }
    Object.values(val).forEach((v) => {
      if (typeof v !== 'number' && !(v instanceof Number)) {
        throw new Error(
          `Must be a JSON array with string items only. Correct: [1,2], wrong: ["1", "2"].`
        )
      }
    })
    return val as number[]
  } catch (err) {
    throw new Error(
      `An error occured while trying to parse JSON array environment variable ${name}: ${err}`
    )
  }
}

function getEnvVar(name: string): string {
  if (process.env[name] == null) {
    throw new Error(
      `Environment variable "${name}" for JS app is not defined (null or undefined).`
    )
  }
  if (isDevEnv() || isStagingEnv()) {
    console.log(name, ':', process.env[name])
  }
  return process.env[name] as string
}

function getJustfoodApiBaseUrl(): string {
  if ((window as any).Cypress) {
    return 'https://localhost:44333' // use random localhost path so that non-intercepted paths fail when running Cypress Tests.
  }

  return getEnvVar('REACT_APP_JUSTFOOD_API_BASE_URL')
}
export const JUSTFOOD_API_BASE_URL = getJustfoodApiBaseUrl()
