import { Header, PageContainer, PageGrid } from './Styles'
import { Footer } from '../../footer'
import { LanguageSelect } from '../../language'

export interface IPageTemplateProps {
  children: React.ReactNode
  mobileHeaderColor?: string
  mobileHeaderBg?: string
}

export function PageTemplateView({
  children,
  mobileHeaderColor,
  mobileHeaderBg,
}: IPageTemplateProps) {
  return (
    <PageContainer>
      <PageGrid>
        <Header
          mobileHeaderColor={mobileHeaderColor}
          mobileHeaderBg={mobileHeaderBg}
        >
          <LanguageSelect />
        </Header>
        {children}
      </PageGrid>
      <Footer />
    </PageContainer>
  )
}
