/**
 * Valid password is 4-14 characters long.
 * Accepted characters are numbers, lower and upper ASCII characters
 *
 * @param passwordString
 * @returns true or false
 */
export function isPasswordInvalid(passwordString: string | null): boolean {
  return passwordString === null || passwordString === ''
    ? false
    : !/^[a-zA-Z0-9]{4,14}$/g.test(passwordString)
}
