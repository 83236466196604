import { extendTheme } from '@chakra-ui/react'
import { radioTheme } from './components/radio'
import { buttonTheme } from './components/button'
import { linkTheme } from './components/link'
import { accordionTheme } from './components/accordion'
import textStyles from './textStyles'
import { popoverTheme } from './components/popover'

const brand = {
  // Option colors:
  ok: '#74B91C', // ok
  little: '#F49300', // little to improve
  alot: '#DE003D', // a lot to improve
  // Hover variations of option colors:
  okHover: 'rgba(116, 185, 28, 0.2)',
  littleHover: 'rgba(244, 147, 0, 0.2)',
  alotHover: 'rgba(222, 0, 61, 0.2)',
  // Primary colors:
  palette1: '#483753', // "aubergine"
  palette2: '#D8DE3F', // "avocado"
  palette3: '#808E2D', // "basil"
  palette4: '#F6F5F5', // "egshell"
  // Secondary colors:
  palette5: '#B7AB99', // "bean" (used in "not applicable")
  palette5Hover: '#E8E3DD',
  palette6: '#801A36', // "cherry"
  palette7: '#005B7D', // "blueberry"
  // Info button states:
  palette8: 'rgba(72, 55, 83, 0.5)', // info selected
  palette9: '#725B81', // info/button hover
  palette10: '#EBEDF0', // button disabled
  palette11: '#ACB4BF', // disabled button text (secondary)
  palette12: '#9BA94A', // hover variant for "avocado"
  palette13: '#C4C4C4',
  palette14: '#897895', // area "sosiocultural fairness"
  palette15: '#737C36', // darkened "basil" - for enough contrast
  warning: '#DE003D',
}

const footer = '2.75rem'

const theme = extendTheme({
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    brand,
  },
  components: {
    Radio: radioTheme,
    Button: buttonTheme,
    Link: linkTheme,
    Accordion: accordionTheme,
    Popover: popoverTheme,
  },
  fonts: {
    heading: `'Janitor', sans-serif`,
    body: `'Century Gothic', sans-serif`,
  },
  textStyles: textStyles,
  sizes: {
    footer,
    pageContainer: '100vh',
  },
  space: {
    mxForMainContentMobile: '1rem',
    mxForMainContent: '1.5rem',
    mxForMainContentXL: '3rem',
  },
})

export default theme
