import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import { pdfStyles } from './PdfStyles'
import JustFoodIcon from '../../../theme/images/JustFoodIcon.png'
import { useTranslation } from 'react-i18next'
import { ISettings, OptionType } from '../../types'
import AreaIcon1 from '../../../theme/images/areaicon1.png'
import AreaIcon2 from '../../../theme/images/areaicon2.png'
import AreaIcon3 from '../../../theme/images/areaicon3.png'
import AreaIcon4 from '../../../theme/images/areaicon4.png'
import AreaIcon5 from '../../../theme/images/areaicon5.png'
import AreaIcon6 from '../../../theme/images/areaicon6.png'
import AreaIcon7 from '../../../theme/images/areaicon7.png'
import { formatFullDate } from '../../utils/dateFormatting'
import { TFunction } from 'i18next'
import { ISummaryView } from './SummaryChart'

interface IPdfProps {
  data: ISettings
  summary: ISummaryView
}

export const PdfGenerator = ({ data, summary }: IPdfProps) => {
  const { t, i18n } = useTranslation()
  const title = t('pdf.title')
  const subTitle = t('pdf.subtitle')
  const pageIndex = t('pdf.pageNumber')

  const questionAnswerPairs = data.areas.map((area) => {
    const areaHeader = t(
      `${data.organizationType}.area_${area.areaIndex + 1}.title`
    )

    const conditionalStyles = StyleSheet.create({
      areaHeader: {
        backgroundColor: getAreaHeaderColor(area.areaIndex + 1),
        color: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '35pt',
        borderRadius: '50%',
      },
    })

    const areaImg = getAreaIconPath(area.areaIndex + 1)

    return (
      <View key={area.areaIndex} style={pdfStyles.singleArea}>
        <View style={conditionalStyles.areaHeader} wrap={false}>
          {areaImg !== undefined && (
            <Image src={areaImg} style={pdfStyles.areaImage} />
          )}
          <Text style={pdfStyles.areaTitle}>{areaHeader}</Text>
        </View>
        {area.answers.length > 0 ? (
          area.answers.map((answer) => {
            const question = t(
              `${data.organizationType}.area_${area.areaIndex + 1}.question_${
                answer.answerIndex + 1
              }.title`
            )
            const chosenOption = t(
              `${data.organizationType}.area_${area.areaIndex + 1}.question_${
                answer.answerIndex + 1
              }.${answer.option}`
            )
            const optionStyles = StyleSheet.create({
              optionType: {
                height: '14pt',
                width: '14pt',
                marginRight: '7pt',
                borderRadius: '50%',
                backgroundColor: getOptionColor(answer.option),
              },
            })

            return (
              <View key={answer.answerIndex} style={pdfStyles.singleQuestion}>
                <Text style={pdfStyles.questionText}>
                  {answer.answerIndex + 1}. {question}
                </Text>
                {answer.option ? (
                  <View style={pdfStyles.answerContainer}>
                    <View style={optionStyles.optionType}></View>
                    <Text style={pdfStyles.answerText}>{chosenOption}</Text>
                  </View>
                ) : (
                  <View style={pdfStyles.answerContainer}>
                    <Text style={pdfStyles.missingAnswer}>
                      {t('pdf.unanswered')}
                    </Text>
                  </View>
                )}
                {answer.comment && (
                  <View style={pdfStyles.commentContainer}>
                    <Text>
                      <Text style={pdfStyles.commentLead}>
                        {`${t('pdf.comment')}: `}
                      </Text>
                      {answer.comment}
                    </Text>
                  </View>
                )}
              </View>
            )
          })
        ) : (
          <View style={pdfStyles.singleQuestion}>
            <Text style={pdfStyles.missingAnswer}>
              {t('pdf.unansweredSection')}
            </Text>
          </View>
        )}
      </View>
    )
  })

  const printDate = formatFullDate(new Date(), i18n.language)
  const backgroundDetailsAndFreecomment = printBackgroundDetailsAndFreecomment(
    data,
    t
  )
  const summaryTitle = `${t('summary.answers')} (${summary.totalAnswers} / ${
    summary.totalQuestions
  })`
  const answerSummary = printAnswerSummary(summary, t)

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.header}>
          <Image src={JustFoodIcon} style={pdfStyles.justFoodIcon} />
          <View>
            <Text style={pdfStyles.title}>{title}</Text>
            <Text style={pdfStyles.subtitle}>{subTitle}</Text>
          </View>
        </View>
        <View style={pdfStyles.detailsSection}>
          {backgroundDetailsAndFreecomment}
        </View>
        <View style={pdfStyles.summary}>
          <Text style={pdfStyles.summaryTitle}>{summaryTitle}</Text>
          <View style={pdfStyles.summaryOptionsWrapper}>{answerSummary}</View>
        </View>
        <View>{questionAnswerPairs}</View>
        <View fixed style={pdfStyles.pageFooter}>
          <Text>{t('pdf.printDate', { date: printDate })}</Text>
          <Text
            style={pdfStyles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageIndex} ${pageNumber} / ${totalPages}`
            }
          />
          <Text style={pdfStyles.footerTitle}>{t('pdf.footerTitle')}</Text>
        </View>
      </Page>
    </Document>
  )
}

const printAnswerSummary = (summary: ISummaryView, t: TFunction) => {
  return Object.values(OptionType).map((option) => {
    const summarStyles = StyleSheet.create({
      optionType: {
        height: '10pt',
        width: '10pt',
        marginRight: '4pt',
        borderRadius: '50%',
        backgroundColor: getOptionColor(option),
      },
    })
    const count = getOptionCount(option, summary)
    const optionText = getOptionName(option, t)

    return (
      <View key={option} style={pdfStyles.summaryOption}>
        <View style={summarStyles.optionType}></View>
        <Text>{count}</Text>
        <Text>{optionText}</Text>
      </View>
    )
  })
}

const printBackgroundDetailsAndFreecomment = (
  data: ISettings,
  t: TFunction
) => {
  const operator = data.operator || ''
  const timeframe = data.timeframe || ''
  const freecomment = data.freecomment || ''

  return timeframe || operator || freecomment ? (
    <>
      {operator && (
        <View style={pdfStyles.details}>
          <Text style={pdfStyles.detailsTitle}>
            {t('pdf.programoroperator')}
          </Text>
          <Text>{operator}</Text>
        </View>
      )}
      {timeframe && (
        <View style={pdfStyles.details}>
          <Text style={pdfStyles.detailsTitle}>{t('pdf.timeframe')}</Text>
          <Text>{timeframe}</Text>
        </View>
      )}
      {freecomment && (
        <View style={pdfStyles.details}>
          <Text style={pdfStyles.detailsTitle}>{t('pdf.freecomment')}</Text>
          <Text>{freecomment}</Text>
        </View>
      )}
    </>
  ) : (
    <></>
  )
}

const getAreaHeaderColor = (index: number): string => {
  switch (index) {
    case 1:
      return '#483753'
    case 2:
      return '#808E2D'
    case 3:
      return '#801A36'
    case 4:
      return '#005B7D'
    case 5:
      return '#B7AB99'
    case 6:
      return '#897895'
    case 7:
      return '#BB617A'
    default:
      return '#483753'
  }
}

const getOptionCount = (option: OptionType, summary: ISummaryView): number => {
  switch (option) {
    case OptionType.OK:
      return summary.answerOk
    case OptionType.LITTLE_TO_IMPROVE:
      return summary.answerLittle
    case OptionType.A_LOT_TO_IMPROVE:
      return summary.answerLot
    case OptionType.NOT_APPLICABLE:
      return summary.answerNot
    default:
      return 0
  }
}

const getOptionName = (option: OptionType, t: TFunction): string => {
  switch (option) {
    case OptionType.OK:
      return t('summary.answerok')
    case OptionType.LITTLE_TO_IMPROVE:
      return t('summary.answerlittle')
    case OptionType.A_LOT_TO_IMPROVE:
      return t('summary.answerlot')
    case OptionType.NOT_APPLICABLE:
      return t('summary.answernot')
    default:
      return ''
  }
}

const getOptionColor = (option: OptionType | undefined): string => {
  switch (option) {
    case OptionType.OK:
      return '#74B91C'
    case OptionType.LITTLE_TO_IMPROVE:
      return '#F49300'
    case OptionType.A_LOT_TO_IMPROVE:
      return '#DE003D'
    case OptionType.NOT_APPLICABLE:
      return '#B7AB99'
    default:
      return '#B7AB99'
  }
}

const getAreaIconPath = (areaIndex: number): string | undefined => {
  switch (areaIndex) {
    case 1:
      return AreaIcon1
    case 2:
      return AreaIcon2
    case 3:
      return AreaIcon3
    case 4:
      return AreaIcon4
    case 5:
      return AreaIcon5
    case 6:
      return AreaIcon6
    case 7:
      return AreaIcon7
    default:
      return undefined
  }
}
