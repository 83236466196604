/* tslint:disable */
/* eslint-disable */
/**
 * JustFood API
 * <b>Edit this text in the swaggerDescription.html</b>  <br/>
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  CreateJustFoodCommand,
  CreateJustFoodCommandFromJSON,
  CreateJustFoodCommandToJSON,
  DeleteJustFoodCommand,
  DeleteJustFoodCommandFromJSON,
  DeleteJustFoodCommandToJSON,
  JustFoodDTO,
  JustFoodDTOFromJSON,
  JustFoodDTOToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  UpdateJustFoodCommand,
  UpdateJustFoodCommandFromJSON,
  UpdateJustFoodCommandToJSON,
  ValidationProblemDetails,
  ValidationProblemDetailsFromJSON,
  ValidationProblemDetailsToJSON,
} from '../models'

export interface ApiJustFoodsGetRequest {
  apiVersion: string
}

export interface ApiJustFoodsLinkDeleteRequest {
  link: string
  apiVersion: string
  deleteJustFoodCommand?: DeleteJustFoodCommand
}

export interface ApiJustFoodsLinkGetRequest {
  link: string
  apiVersion: string
  password?: string
}

export interface ApiJustFoodsLinkPasswordGetRequest {
  link: string
  password: string
  apiVersion: string
}

export interface ApiJustFoodsLinkPutRequest {
  link: string
  apiVersion: string
  updateJustFoodCommand?: UpdateJustFoodCommand
}

export interface ApiJustFoodsPostRequest {
  apiVersion: string
  createJustFoodCommand?: CreateJustFoodCommand
}

/**
 *
 */
export class JustFoodsApi extends runtime.BaseAPI {
  /**
   * DEBUG API to receive all just food data.              This is only for development use
   */
  async apiJustFoodsGetRaw(
    requestParameters: ApiJustFoodsGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<JustFoodDTO>>> {
    if (
      requestParameters.apiVersion === null ||
      requestParameters.apiVersion === undefined
    ) {
      throw new runtime.RequiredError(
        'apiVersion',
        'Required parameter requestParameters.apiVersion was null or undefined when calling apiJustFoodsGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.apiVersion !== undefined) {
      queryParameters['api-version'] = requestParameters.apiVersion
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/api/JustFoods`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(JustFoodDTOFromJSON)
    )
  }

  /**
   * DEBUG API to receive all just food data.              This is only for development use
   */
  async apiJustFoodsGet(
    requestParameters: ApiJustFoodsGetRequest,
    initOverrides?: RequestInit
  ): Promise<Array<JustFoodDTO>> {
    const response = await this.apiJustFoodsGetRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * Deletes an existing Just Food entity by Link and optional password.
   */
  async apiJustFoodsLinkDeleteRaw(
    requestParameters: ApiJustFoodsLinkDeleteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.link === null ||
      requestParameters.link === undefined
    ) {
      throw new runtime.RequiredError(
        'link',
        'Required parameter requestParameters.link was null or undefined when calling apiJustFoodsLinkDelete.'
      )
    }

    if (
      requestParameters.apiVersion === null ||
      requestParameters.apiVersion === undefined
    ) {
      throw new runtime.RequiredError(
        'apiVersion',
        'Required parameter requestParameters.apiVersion was null or undefined when calling apiJustFoodsLinkDelete.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.apiVersion !== undefined) {
      queryParameters['api-version'] = requestParameters.apiVersion
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] =
      'application/json;odata.metadata=minimal;odata.streaming=true'

    const response = await this.request(
      {
        path: `/api/JustFoods/{link}`.replace(
          `{${'link'}}`,
          encodeURIComponent(String(requestParameters.link))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: DeleteJustFoodCommandToJSON(
          requestParameters.deleteJustFoodCommand
        ),
      },
      initOverrides
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Deletes an existing Just Food entity by Link and optional password.
   */
  async apiJustFoodsLinkDelete(
    requestParameters: ApiJustFoodsLinkDeleteRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.apiJustFoodsLinkDeleteRaw(requestParameters, initOverrides)
  }

  /**
   * Request Just Food data with unique Link and optional password parameter.
   */
  async apiJustFoodsLinkGetRaw(
    requestParameters: ApiJustFoodsLinkGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<JustFoodDTO>> {
    if (
      requestParameters.link === null ||
      requestParameters.link === undefined
    ) {
      throw new runtime.RequiredError(
        'link',
        'Required parameter requestParameters.link was null or undefined when calling apiJustFoodsLinkGet.'
      )
    }

    if (
      requestParameters.apiVersion === null ||
      requestParameters.apiVersion === undefined
    ) {
      throw new runtime.RequiredError(
        'apiVersion',
        'Required parameter requestParameters.apiVersion was null or undefined when calling apiJustFoodsLinkGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.password !== undefined) {
      queryParameters['password'] = requestParameters.password
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters['api-version'] = requestParameters.apiVersion
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/api/JustFoods/{link}`.replace(
          `{${'link'}}`,
          encodeURIComponent(String(requestParameters.link))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      JustFoodDTOFromJSON(jsonValue)
    )
  }

  /**
   * Request Just Food data with unique Link and optional password parameter.
   */
  async apiJustFoodsLinkGet(
    requestParameters: ApiJustFoodsLinkGetRequest,
    initOverrides?: RequestInit
  ): Promise<JustFoodDTO> {
    const response = await this.apiJustFoodsLinkGetRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * Request Just Food data with link and base 64 encoded password.
   */
  async apiJustFoodsLinkPasswordGetRaw(
    requestParameters: ApiJustFoodsLinkPasswordGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<JustFoodDTO>> {
    if (
      requestParameters.link === null ||
      requestParameters.link === undefined
    ) {
      throw new runtime.RequiredError(
        'link',
        'Required parameter requestParameters.link was null or undefined when calling apiJustFoodsLinkPasswordGet.'
      )
    }

    if (
      requestParameters.password === null ||
      requestParameters.password === undefined
    ) {
      throw new runtime.RequiredError(
        'password',
        'Required parameter requestParameters.password was null or undefined when calling apiJustFoodsLinkPasswordGet.'
      )
    }

    if (
      requestParameters.apiVersion === null ||
      requestParameters.apiVersion === undefined
    ) {
      throw new runtime.RequiredError(
        'apiVersion',
        'Required parameter requestParameters.apiVersion was null or undefined when calling apiJustFoodsLinkPasswordGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.apiVersion !== undefined) {
      queryParameters['api-version'] = requestParameters.apiVersion
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/api/JustFoods/{link}/{password}`
          .replace(
            `{${'link'}}`,
            encodeURIComponent(String(requestParameters.link))
          )
          .replace(
            `{${'password'}}`,
            encodeURIComponent(String(requestParameters.password))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      JustFoodDTOFromJSON(jsonValue)
    )
  }

  /**
   * Request Just Food data with link and base 64 encoded password.
   */
  async apiJustFoodsLinkPasswordGet(
    requestParameters: ApiJustFoodsLinkPasswordGetRequest,
    initOverrides?: RequestInit
  ): Promise<JustFoodDTO> {
    const response = await this.apiJustFoodsLinkPasswordGetRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * Updates an existing Just Food entity
   */
  async apiJustFoodsLinkPutRaw(
    requestParameters: ApiJustFoodsLinkPutRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.link === null ||
      requestParameters.link === undefined
    ) {
      throw new runtime.RequiredError(
        'link',
        'Required parameter requestParameters.link was null or undefined when calling apiJustFoodsLinkPut.'
      )
    }

    if (
      requestParameters.apiVersion === null ||
      requestParameters.apiVersion === undefined
    ) {
      throw new runtime.RequiredError(
        'apiVersion',
        'Required parameter requestParameters.apiVersion was null or undefined when calling apiJustFoodsLinkPut.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.apiVersion !== undefined) {
      queryParameters['api-version'] = requestParameters.apiVersion
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] =
      'application/json;odata.metadata=minimal;odata.streaming=true'

    const response = await this.request(
      {
        path: `/api/JustFoods/{link}`.replace(
          `{${'link'}}`,
          encodeURIComponent(String(requestParameters.link))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateJustFoodCommandToJSON(
          requestParameters.updateJustFoodCommand
        ),
      },
      initOverrides
    )

    return new runtime.TextApiResponse(response) as any
  }

  /**
   * Updates an existing Just Food entity
   */
  async apiJustFoodsLinkPut(
    requestParameters: ApiJustFoodsLinkPutRequest,
    initOverrides?: RequestInit
  ): Promise<string> {
    const response = await this.apiJustFoodsLinkPutRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * Creates a new Just Food
   */
  async apiJustFoodsPostRaw(
    requestParameters: ApiJustFoodsPostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.apiVersion === null ||
      requestParameters.apiVersion === undefined
    ) {
      throw new runtime.RequiredError(
        'apiVersion',
        'Required parameter requestParameters.apiVersion was null or undefined when calling apiJustFoodsPost.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.apiVersion !== undefined) {
      queryParameters['api-version'] = requestParameters.apiVersion
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] =
      'application/json;odata.metadata=minimal;odata.streaming=true'

    const response = await this.request(
      {
        path: `/api/JustFoods`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateJustFoodCommandToJSON(
          requestParameters.createJustFoodCommand
        ),
      },
      initOverrides
    )

    return new runtime.TextApiResponse(response) as any
  }

  /**
   * Creates a new Just Food
   */
  async apiJustFoodsPost(
    requestParameters: ApiJustFoodsPostRequest,
    initOverrides?: RequestInit
  ): Promise<string> {
    const response = await this.apiJustFoodsPostRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }
}
