export function formatFullDate(date: Date, language: string): string {
  switch (language) {
    case 'fi':
      return date.toLocaleString('fi-FI')
    case 'en':
      return date.toLocaleString('en-EN')
    default:
      console.warn(
        `Unsupported full date format language: ${language} fallbacking to en`
      )
      return formatFullDate(date, 'en')
  }
}
