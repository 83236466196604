import { i18n } from 'i18next'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTE_BASE_PATH, SUPPORTED_LANGUAGES } from '../../../config'

const LOCALSTORAGE_LANGUAGE_KEY = `${ROUTE_BASE_PATH}_language`

export default function () {
  const { i18n } = useTranslation()
  const language = i18n.language

  useEffect(
    () => {
      const localStorageLanguage = localStorage.getItem(
        LOCALSTORAGE_LANGUAGE_KEY
      )
      if (localStorageLanguage != null) {
        changeLanguage(localStorageLanguage, i18n)
      }
    },
    [i18n] /* on initial render, set langauge according to localStorage */
  )

  useEffect(
    () => {
      localStorage.setItem(LOCALSTORAGE_LANGUAGE_KEY, language)
    },
    [
      language,
    ] /* if language is changed, it is put as next default in local storage */
  )

  return {
    language,
    languages: SUPPORTED_LANGUAGES,
    changeLanguage: (lang: string) => changeLanguage(lang, i18n),
  }
}

function changeLanguage(lang: string, i18n: i18n) {
  i18n.changeLanguage(lang)
  updateHtmlLangAttr(lang)
}

/**
 * changes <HTML> lang attribute value
 * @param language
 */
function updateHtmlLangAttr(language: string) {
  document.documentElement.lang = language
}
