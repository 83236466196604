import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
  color: 'brand.palette7',
  textDecoration: 'underline',
})

const areaBase = {
  position: 'relative',
  color: 'white',
  borderRadius: 'full',
  textDecoration: 'none',
  _focus: {
    // outline: '3px solid',
    // outlineOffset: '3px',
    // outlineColor: 'brand.palette7',
    /* outline: 'none',
    boxShadow: 'none',
    _before: {
      content: `""`,
      display: 'block',
      position: 'absolute',
      border: '3px solid',
      borderColor: 'brand.palette7',
      borderRadius: '8px',
      top: '-5px',
      left: '-5px',
      right: '-5px',
      bottom: '-5px',
      zIndex: '2',
    }, */
  },
}

const area = defineStyle({
  ...areaBase,
  bg: 'brand.palette1',
  _active: {
    bg: 'brand.palette1',
  },
  _hover: {
    bg: 'brand.palette9',
    textDecoration: 'none',
  },
})

const completedArea = defineStyle({
  ...areaBase,
  bg: 'brand.palette15',
  _active: {
    bg: 'brand.palette1',
  },
  _hover: {
    bg: 'brand.palette12',
    textDecoration: 'none',
  },
})

const outline = defineStyle({
  color: 'brand.palette1',
  borderColor: 'brand.palette1',
  _hover: {
    color: 'brand.palette9',
    borderColor: 'brand.palette9',
    bg: 'none',
  },
})

export const linkTheme = defineStyleConfig({
  baseStyle,
  variants: {
    area,
    completedArea,
    outline,
  },
})
