import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link as ReactLink } from 'react-router-dom'
import { Flex, Img, Spacer, Text } from '@chakra-ui/react'
import useFooterLogic from '../hooks/useFooterLogic'
import Sykelogo_fi from '../../../theme/images/sykelogo_fin.svg'
import Sykelogo_en from '../../../theme/images/sykelogo_en.svg'
import { FooterContent, FooterLinkWrapper, FooterWrapper } from './Styles'

export default ({ t, i18n }: ReturnType<typeof useFooterLogic>) => {
  return (
    <FooterWrapper>
      <Flex alignItems="center">
        <FooterContent>
          <FooterLinkWrapper>
            <ReactLink to="https://www.syke.fi" target="_blank">
              <Img
                src={i18n.language === 'en' ? Sykelogo_en : Sykelogo_fi}
                h="3rem"
              />
            </ReactLink>
          </FooterLinkWrapper>
          <Spacer />
          <FooterLinkWrapper>
            <ReactLink
              to="/documents/justfood/Saavutettavuus.pdf"
              target="_blank"
            >
              <Text textColor={'white'} as="b">
                {t('footer.accessibilityStatement')} <ExternalLinkIcon />
              </Text>
            </ReactLink>
          </FooterLinkWrapper>
          <FooterLinkWrapper>
            <ReactLink
              to="https://www.justfood.fi/fi-FI/content/53925/1"
              target="_blank"
            >
              <Text textColor={'white'} as="b">
                {t('footer.privacyStatement')} <ExternalLinkIcon />
              </Text>
            </ReactLink>
          </FooterLinkWrapper>
          <FooterLinkWrapper>
            <ReactLink
              to="https://www.justfood.fi/fi-FI/Reiluustyokalu"
              target="_blank"
            >
              <Text textColor={'white'} as="b">
                {t('footer.contactInfo')} <ExternalLinkIcon />
              </Text>
            </ReactLink>
          </FooterLinkWrapper>
        </FooterContent>
      </Flex>
    </FooterWrapper>
  )
}
