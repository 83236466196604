import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton,
  SimpleGrid,
  Spinner,
  Tooltip,
} from '@chakra-ui/react'
import React from 'react'
import { MdSave } from 'react-icons/md'
import useMobileLayout from '../../../hooks/useMobileLayout'
import useSaveButtonLogic from '../hooks/useSaveButtonLogic'

interface ISaveButtonProps {
  showOnlyIcon: boolean
}

export const SaveButton = ({ showOnlyIcon }: ISaveButtonProps) => {
  const cancelRef = React.useRef(null)
  const isMobile = useMobileLayout()
  const {
    linkExists,
    loadingData,
    savingData,
    isOpen,
    onClose,
    handleSave,
    handleOverwrite,
    handleReload,
    t,
  } = useSaveButtonLogic()

  return (
    <>
      {showOnlyIcon ? (
        savingData || loadingData ? (
          <Spinner />
        ) : (
          <IconButton
            data-cy="save-form"
            aria-label={t('mainpage.save')}
            onClick={handleSave}
            icon={<MdSave />}
            isDisabled={!linkExists}
          />
        )
      ) : (
        <Tooltip
          label={
            linkExists
              ? t('mainpage.saveenabledtooltip')
              : t('mainpage.savedisabledtooltip')
          }
          aria-label="A tooltip"
        >
          <Button
            data-cy="save-form"
            w="100%"
            isDisabled={!linkExists}
            onClick={handleSave}
          >
            {savingData ? <Spinner /> : t('mainpage.save')}
          </Button>
        </Tooltip>
      )}
      <AlertDialog
        size={'lg'}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent w={'full'}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('mainpage.alerttitle')}
            </AlertDialogHeader>
            <AlertDialogBody>{t('mainpage.alertdescription')}</AlertDialogBody>
            <AlertDialogFooter>
              <SimpleGrid columns={isMobile ? 1 : 2} spacing={3}>
                <Button onClick={handleReload}>{t('mainpage.reload')}</Button>
                <Button onClick={handleOverwrite}>
                  {t('mainpage.overwrite')}
                </Button>
                <Button variant="outline" ref={cancelRef} onClick={onClose}>
                  {t('mainpage.cancel')}
                </Button>
              </SimpleGrid>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
