import { Radio, RadioProps, Icon } from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa'

export const CheckmarkRadio = (props: RadioProps) => {
  const { children, ...rest } = props
  return (
    <Radio {...rest}>
      <Icon
        as={FaCheck}
        className="check-icon"
        position="absolute"
        color="white"
        fontSize="1rem"
        left="-1.75rem"
        top="50%"
        transform="translateY(-50%)"
      />
      {children}
    </Radio>
  )
}
