const textStyles = {
  h1: {
    color: 'brand.palette1',
    fontSize: { lg: '2.625rem' },
    fontWeight: { base: '400', lg: '400' },
    lineHeight: { lg: '2.875rem' },
  },
  lead: {
    fontSize: { base: '1.025rem', md: '1.125rem' },
    fontWeight: { base: '700' },
    lineHeight: { base: '1.5rem' },
  },
  body: {
    fontWeight: '400',
  },
  smallText: {
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: '1.125rem',
  },
  boldText: {
    fontWeight: '700',
  },
}

export default textStyles
