import { popoverAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys)

const baseStyle = definePartsStyle({
  arrow: {
    bg: 'brand.palette2',
  },
  body: {
    bg: 'brand.palette4',
    borderRadius: '0.375rem',
    pr: '1.75rem', // to keep body content and close button from overlapping
  },
  content: {
    borderColor: 'brand.palette1',
    borderWidth: '2px',
    borderRadius: '0.375rem',
    w: { base: 'auto', lg: '600px' },
  },
})
export const popoverTheme = defineMultiStyleConfig({ baseStyle })
