import {
  Button,
  Heading,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLinkPagePasswordLogic } from '../hooks/useLinkPagePasswordLogic'
import { InfoBar, Main, PageTemplate, SideContent } from '../../pageTemplate'
import { isPasswordInvalid } from '../../utils/helpers'
import useMobileLayout from '../../../hooks/useMobileLayout'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export default function LinkPagePasswordView({
  isLoading,
  isError,
  setGetPassword,
  getPassword,
  passwordString,
  setPasswordString,
  handleContinue,
}: ReturnType<typeof useLinkPagePasswordLogic>) {
  const { t } = useTranslation()
  const isMobileView = !!useMobileLayout()

  const placeholderTranslation = t('linkpagepassword.givepassword')
  const InputPatternTitle = t('mainpage.validpassword')

  return (
    <PageTemplate>
      <InfoBar showBackgroundImage />
      <Main>
        <VStack
          minH="75%"
          alignItems={{ base: 'baseline' }}
          justifyContent="center"
          px="1rem"
          gap={30}
        >
          <Heading>{t('linkpagepassword.title')}</Heading>
          <Text>{t('linkpagepassword.description')}</Text>
          <Text>{t('linkpagepassword.description2')}</Text>
          <SimpleGrid columns={isMobileView ? 1 : 2} gap={3}>
            <Input
              title={InputPatternTitle}
              borderColor={isError ? 'brand.warning' : 'brand.palette5'}
              isInvalid={isPasswordInvalid(passwordString)}
              onChange={(event) => {
                setPasswordString(event.target.value)
                const pw = btoa(event.target.value)
                setGetPassword({
                  link: null,
                  password: pw,
                })
              }}
              onKeyUp={(e) => {
                !isPasswordInvalid(passwordString) &&
                  e.key === 'Enter' &&
                  handleContinue()
              }}
              placeholder={placeholderTranslation}
              aria-label={placeholderTranslation}
              maxLength={20}
            ></Input>
            <Button
              isDisabled={
                getPassword?.password == null ||
                isPasswordInvalid(passwordString)
              }
              onClick={handleContinue}
            >
              {isLoading ? <Spinner /> : t('linkpagepassword.continue')}
            </Button>
          </SimpleGrid>
          {isError && (
            <Text textColor={'brand.warning'}>
              {t('linkpagepassword.error')}
            </Text>
          )}
          <Text>{t('linkpagepassword.nopassword')}</Text>
          <ReactLink to="/" color="brand.palette7" target="_blank">
            <Text as="b">
              {t('linkpage.readmore')} <ExternalLinkIcon />
            </Text>
          </ReactLink>
        </VStack>
      </Main>
      <SideContent />
    </PageTemplate>
  )
}
