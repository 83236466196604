import { useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { JustFoodContext, staticContext } from '../../../store'
import useAreaScrollTop from './useAreaScrollTop'

export default () => {
  const { id } = useParams()

  const { userContext, updateUserContext } = useContext(JustFoodContext)

  const organization = staticContext.organizations?.find(
    (o) => o.organizationType === userContext.organizationType
  )
  const areaQuestions = organization?.areaQuestionCounts
  const totalQuestions = areaQuestions?.reduce(
    (prev, curr) => (Number(prev) || 0) + (Number(curr) || 0)
  )
  const areaAnswers = userContext.areas.map((a) => a.answers)
  const totalAnswers = areaAnswers.flat()
  const answered = totalAnswers.filter((a) => a.option !== undefined)
  const isFinished =
    (totalQuestions && totalQuestions === answered.length) || false

  const pageNumber = parseInt(id!)
  const areaIndex = pageNumber - 1
  const areaPage = organization?.areaQuestionCounts![areaIndex] || 0
  const numberOfAreas = organization?.areaQuestionCounts.length || 0

  const firstQuestionRef = useRef<HTMLParagraphElement>(null)
  useAreaScrollTop(firstQuestionRef, areaIndex)

  useEffect(() => {
    if (
      userContext.areas.length > 0 &&
      userContext.areas[areaIndex].answers.length === 0
    ) {
      let ctx = { ...userContext }
      for (var i = 0; i < areaPage; i++) {
        ctx.areas[areaIndex].answers.push({
          answerIndex: i,
        })
      }
      updateUserContext(ctx)
    }
  }, [userContext, areaIndex, areaPage, updateUserContext])

  const onChangeComment = (i: number, comment: string) => {
    let ctx = { ...userContext }
    ctx.areas[areaIndex].answers[i].comment = comment
    updateUserContext(ctx)
  }

  return {
    pageNumber,
    numberOfAreas,
    userContext,
    updateUserContext,
    onChangeComment,
    areaIndex,
    firstQuestionRef,
    isFinished,
  }
}
