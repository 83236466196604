import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
    /* latin */
    @font-face {
      font-family: 'Janitor';
      font-style: normal;
      font-weight: 400;
      src: local('Janitor'), url('https://fonts.cdnfonts.com/s/53673/Janitor.woff') format('woff');
    },
    @font-face {
      font-family: century gothic;
      font-style: normal;
      font-weight: 400;
      src: local('Century Gothic'), url(https://fonts.cdnfonts.com/s/18111/GOTHIC.woff) format('woff')
  },
  @font-face {
      font-family: century gothic;
      font-style: italic;
      font-weight: 400;
      src: local('Century Gothic'), url(https://fonts.cdnfonts.com/s/18111/GOTHICI.woff) format('woff')
  }
  
    `}
  />
)

export default Fonts
