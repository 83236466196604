import { useContext } from 'react'
import { JustFoodContext, staticContext } from '../../../store'
import { OrganizationType } from '../../types'

export default function () {
  const { userContext, updateUserContext } = useContext(JustFoodContext)

  function handleStartButton() {
    const organization = staticContext.organizations.find(
      (o) => o.organizationType === userContext.organizationType
    )
    var areas = organization?.areaQuestionCounts?.length ?? 0
    let ctx = { ...userContext }
    for (var i = 0; i < areas; i++) {
      ctx.areas.push({
        areaIndex: i,
        answers: [],
      })
    }
    updateUserContext(ctx)
  }

  function handleOrganizationTypeChange(value: string) {
    const type = (userContext.organizationType = value as OrganizationType)
    const ctx = { ...userContext, organizationType: type }
    updateUserContext(ctx)
  }

  function handleOperatorChange(value: string) {
    const operatorValue = (userContext.operator = value)
    const ctx = { ...userContext, operator: operatorValue }
    updateUserContext(ctx)
  }

  function handleTimeframeChange(value: string) {
    const timeframeValue = (userContext.timeframe = value)
    const ctx = { ...userContext, timeframe: timeframeValue }
    updateUserContext(ctx)
  }
  return {
    handleStartButton,
    handleOrganizationTypeChange,
    handleOperatorChange,
    handleTimeframeChange,
  }
}
