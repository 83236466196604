import { useContext } from 'react'
import { ISummaryView } from '../components/SummaryChart'
import { OptionType } from '../../types'
import { JustFoodContext, staticContext } from '../../../store'

export type IParseSnapshot = ISummaryView & {
  organization: string
  link: string
  areaQuestions: number
  areaAnswers: number
}

export function useParseSnapshot() {
  const { userContext } = useContext(JustFoodContext)

  const operator = userContext.operator
  const timeframe = userContext.timeframe

  const organization = staticContext.organizations.find(
    (o) => o.organizationType === userContext.organizationType
  )
  const areaQuestions = organization?.areaQuestionCounts
  const totalQuestions = areaQuestions?.reduce(
    (prev, curr) => (Number(prev) || 0) + (Number(curr) || 0)
  )
  const areaAnswers = userContext.areas.map((a) => a.answers)
  const totalAnswers = areaAnswers.flat()
  const areaProgress = areaQuestions?.map((q, i) => {
    if (q === undefined || q === 0) {
      return 0
    }
    const answers =
      areaAnswers?.at(i)?.filter((a) => a.option !== undefined)?.length ?? 0
    return (answers / q) * 100
  })
  const answered = totalAnswers.filter((a) => a.option !== undefined)
  const answerOk = answered.filter((a) => a.option === OptionType.OK)
  const answerLittle = answered.filter(
    (a) => a.option === OptionType.LITTLE_TO_IMPROVE
  )
  const answerLot = answered.filter(
    (a) => a.option === OptionType.A_LOT_TO_IMPROVE
  )
  const answerNot = answered.filter(
    (a) => a.option === OptionType.NOT_APPLICABLE
  )
  const notAnswered = (totalQuestions ?? 0) - (answered.length ?? 0)

  return {
    organization,
    link: userContext.link,
    totalQuestions: totalQuestions,
    totalAnswers: answered.length,
    areaQuestions: areaQuestions?.map((a) => a),
    areaAnswers: areaAnswers.map((a) => a.length),
    areaProgress,
    answerOk: answerOk?.length,
    answerLittle: answerLittle?.length,
    answerLot: answerLot?.length,
    answerNot: answerNot?.length,
    notAnswered: notAnswered,
    isFinished: totalQuestions && totalQuestions === answered.length,
    operator,
    timeframe,
  }
}
