import useLanguage from './useLanguage'

export default function () {
  const { language, languages, changeLanguage } = useLanguage()
  const otherLanguages = languages.filter((l) => l !== language)
  return {
    otherLanguages: otherLanguages.map((id) => ({
      id,
    })),
    changeLanguage: changeLanguage,
  }
}
