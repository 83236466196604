export type ISettings = {
  organizationType?: OrganizationType
  link?: string
  code?: string
  password?: string
  areas: IArea[]
  operator?: string
  timeframe?: string
  freecomment?: string
}

export type IArea = {
  areaIndex: number
  answers: IOption[]
}

export type IOption = {
  answerIndex: number
  option?: OptionType
  comment?: string
}

export enum OptionType {
  'OK' = 'Ok',
  'LITTLE_TO_IMPROVE' = 'LittleToImprove',
  'A_LOT_TO_IMPROVE' = 'ALotToImprove',
  'NOT_APPLICABLE' = 'NotApplicable',
}

export type IJustFood = {
  frontPage?: IPage
  organizations: IOrganizations[]
}

export type IOrganizations = {
  organizationType?: OrganizationType
  areaQuestionCounts: number[]
}

export enum OrganizationType {
  'COMPANY' = 'company',
  'PUBLIC' = 'public',
  'FOODSERVICES' = 'foodservices',
  'ORGANIZATIONS' = 'organizations',
}

export type IPage = {
  title?: string
  description?: string
  logo?: ILogo
}
export type IAreaPage = IPage & {
  questions?: IQuestion[]
}

export type ILogo = {
  url: string
  alt: string
}

export type IQuestion = {
  questionIndex: number
  title?: string
  infoText?: string
}

export type IStatistics = {
  total: IAreaStatistics
  areas: IAreaStatistics[]
}
export type IAreaStatistics = {
  areaIndex: number
  questions: number
  answers: number
  progress: number
}

export type IMeter = {
  text: string
  url: string
}
