import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { JustFoodContext } from '../../../store'
import { AutoResizeTextarea } from '../../areaPage/components/AutoResizeTextarea'
import { InfoBar, Main, PageTemplate, SideContent } from '../../pageTemplate'
import { Link as ReactLink } from 'react-router-dom'
import { AppRoute } from '../../../routes'

export default function CommentView() {
  const { t } = useTranslation()
  const { userContext, updateUserContext } = useContext(JustFoodContext)

  const onChangeFreeComment = (comment: string) => {
    let ctx = { ...userContext }
    ctx.freecomment = comment
    updateUserContext(ctx)
  }

  return (
    <PageTemplate>
      <InfoBar showBackgroundImage />
      <Main>
        <Box px={{ md: '1rem' }}>
          <FormControl>
            <FormLabel>
              <Text
                textStyle="lead"
                color="brand.palette1"
                fontSize="1.5rem"
                mb={{ base: '1rem', lg: '1.5rem' }}
              >
                {t('commentpage.freecomment')}
              </Text>
            </FormLabel>
            <AutoResizeTextarea
              data-cy="free-comment"
              placeholder={t('commentpage.placeholder') ?? undefined}
              value={userContext.freecomment ?? ''}
              onChange={(event) => {
                onChangeFreeComment(event.target.value)
              }}
              maxLength={500}
              minRows={5}
            />
          </FormControl>
          <Flex my={{ base: '1.5rem', md: '2.5rem', lg: '5rem' }}>
            <ReactLink to={AppRoute.MAIN_PAGE}>
              <Button variant="outline">{t('areapage.buttons.area')}</Button>
            </ReactLink>
          </Flex>
        </Box>
      </Main>
      <SideContent />
    </PageTemplate>
  )
}
