import { RefObject, useEffect } from 'react'

/**
 * Hook for scrolling specific item (focusableItem) into view when param (areaIndex)
 * changes
 *
 * @param focusableItem
 * @param areaIndex - Question area index that determines questions shown
 * @returns
 */
export default function (
  focusableItem: RefObject<HTMLElement>,
  areaIndex: number
) {
  useEffect(() => {
    if (focusableItem.current) {
      focusableItem.current.focus()
      focusableItem.current.scrollTo(0, 0)
    }
  }, [focusableItem, areaIndex])
}
