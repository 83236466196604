import { AppRoute } from '../../../routes'
import {
  AreaCompleteCheck,
  AreaCompleteIcon,
  AreaTitle,
  StyledAreaLink,
} from './Styles'
import { useTranslation } from 'react-i18next'

interface ICommentLinkProps {
  comment: string | undefined
}

export function CommentLink({ comment }: ICommentLinkProps) {
  const { t } = useTranslation()
  const areaComplete = comment !== undefined && comment.length > 0

  return (
    <StyledAreaLink
      data-cy={`comment-link`}
      variant={areaComplete ? 'completedArea' : 'area'}
      to={AppRoute.COMMENT_PAGE}
    >
      <AreaCompleteCheck>
        {areaComplete && <AreaCompleteIcon />}
      </AreaCompleteCheck>
      <AreaTitle>{t('commentpage.linktopage')}</AreaTitle>
    </StyledAreaLink>
  )
}
