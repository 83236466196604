/* tslint:disable */
/* eslint-disable */
/**
 * JustFood API
 * <b>Edit this text in the swaggerDescription.html</b>  <br/>
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateJustFoodCommand
 */
export interface UpdateJustFoodCommand {
  /**
   *
   * @type {string}
   * @memberof UpdateJustFoodCommand
   */
  content?: string
  /**
   *
   * @type {string}
   * @memberof UpdateJustFoodCommand
   */
  code?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateJustFoodCommand
   */
  password?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateJustFoodCommand
   */
  link?: string
}

export function UpdateJustFoodCommandFromJSON(
  json: any
): UpdateJustFoodCommand {
  return UpdateJustFoodCommandFromJSONTyped(json, false)
}

export function UpdateJustFoodCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateJustFoodCommand {
  if (json === undefined || json === null) {
    return json
  }
  return {
    content: !exists(json, 'content') ? undefined : json['content'],
    code: !exists(json, 'code') ? undefined : json['code'],
    password: !exists(json, 'password') ? undefined : json['password'],
    link: !exists(json, 'link') ? undefined : json['link'],
  }
}

export function UpdateJustFoodCommandToJSON(
  value?: UpdateJustFoodCommand | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    content: value.content,
    code: value.code,
    password: value.password,
    link: value.link,
  }
}
