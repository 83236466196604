import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'

const baseStyle = defineStyle({})

const sizes = {
  md: defineStyle({
    fontSize: 'xl', // Change font size to sm (14px)
  }),
}

// Default variant for buttons
const solid = defineStyle({
  bg: 'brand.palette1',
  color: 'white',
  _hover: {
    bg: 'brand.palette9',
    _disabled: {
      bg: 'brand.palette13',
    },
  },
  _focused: {
    outline: '2px solid',
    outlineColor: 'brand.palette7',
    outlineOffset: '3px',
  },
  _disabled: {
    bg: 'brand.palette13',
    color: 'brand.palette1',
  },
})

const outlineDisabled = defineStyle({
  bg: 'brand.palette10',
  color: 'brand.palette11',
  borderColor: 'brand.palette10',
})

const outline = defineStyle({
  color: 'brand.palette1',
  borderColor: 'brand.palette1',
  _disabled: {
    ...outlineDisabled,
  },
  _hover: {
    color: 'brand.palette9',
    borderColor: 'brand.palette9',
    bg: 'none',
    _disabled: {
      ...outlineDisabled,
    },
  },
})

export const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes,
  variants: {
    solid,
    outline,
  },
})
