import { useBreakpointValue } from '@chakra-ui/react'

/**
 * Hook for detecting when dealing with smallest screen sizes aka mobile devices
 *
 * @returns false if medium or larger viewport, true if small
 */
export default function () {
  return useBreakpointValue({
    base: true,
    md: false,
  })
}
