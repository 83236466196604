import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { JustFoodContext } from '../../../store'
import { useJustFoodApiGet } from '../../justFoodApi'
import { IJustFoodGet } from '../../justFoodApi/hooks/useJustFoodLogic'
import { ISettings } from '../../types'

export interface ILinkPageLogic {
  isSuccess?: boolean
  isLoading?: boolean
}
export function useLinkPageLogic(): ILinkPageLogic {
  const { link } = useParams()

  const { userContext, resetUserContext, updateUserContext } =
    useContext(JustFoodContext)

  const [getData, setGetData] = useState<IJustFoodGet | null>(
    link != null ? { link: link, password: undefined } : null
  )
  const getRequest = useJustFoodApiGet(getData)
  useEffect(() => {
    if (getRequest.isSuccess) {
      setGetData(null)
      // TODO: Validate data
      const data = getRequest.data
      const content: ISettings = JSON.parse(data.content!)
      const ctx: ISettings = {
        ...userContext,
        link: data.link,
        areas: content.areas,
        organizationType: content.organizationType,
        code: data.code,
        operator: content.operator,
        timeframe: content.timeframe,
        freecomment: content.freecomment,
      }
      updateUserContext(ctx)
    } else if (getRequest.isError) {
      resetUserContext()
    }
  }, [getRequest, userContext, resetUserContext, updateUserContext])
  return {
    isLoading: getRequest.isLoading,
    isSuccess: getRequest.isSuccess || getRequest.isIdle,
  }
}
