import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './components/ErrorFallback'

interface IProps {
  children: React.ReactNode
}

export function GlobalErrorBoundary({ children }: IProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  )
}
