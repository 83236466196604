import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import useMobileLayout from '../../../hooks/useMobileLayout'
import useErrorFallbackLogic from '../hooks/useErrorFallbackLogic'

export default function ({
  reloadPage,
  navigateToRoot,
  t,
}: ReturnType<typeof useErrorFallbackLogic>) {
  const { onClose, isOpen } = useDisclosure({ defaultIsOpen: true })
  const reloadRef = React.useRef<any>()
  const isMobile = useMobileLayout()
  return (
    <AlertDialog
      isOpen={isOpen}
      isCentered
      autoFocus={true}
      closeOnOverlayClick={false}
      leastDestructiveRef={reloadRef}
      onClose={onClose}
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{t('errors.globalErrorTitle')}</AlertDialogHeader>
        <AlertDialogBody>{t('errors.globalErrorDescription')}</AlertDialogBody>
        <AlertDialogFooter>
          <SimpleGrid columns={isMobile ? 1 : 2} gap={3}>
            <Button
              ref={reloadRef}
              className="mt-4"
              onClick={() => {
                reloadPage()
                onClose()
              }}
            >
              {t('errors.refresh')}
            </Button>
            <Button
              className="mt-4"
              onClick={() => {
                navigateToRoot()
              }}
            >
              {t('errors.returnToFrontPage')}
            </Button>
          </SimpleGrid>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
