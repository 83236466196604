import { chakra, Flex } from '@chakra-ui/react'

export const FooterWrapper = chakra('footer', {
  baseStyle: {
    w: '100%',
    background: 'brand.palette1',
    flexShrink: 0,
  },
})

export const FooterContent = chakra(Flex, {
  baseStyle: {
    minHeight: '100%',
    flexDir: { base: 'column', lg: 'row' },
    alignItems: { base: 'flex-start', lg: 'center' },
    justifyContent: 'flex-end',
    pl: { base: '1rem', lg: '2rem' },
    py: { base: '1.5rem', lg: '0.5rem' },
    width: '100%',
  },
})

export const FooterLinkWrapper = chakra(Flex, {
  baseStyle: {
    color: 'white',
    alignItems: { base: 'flex-start', lg: 'center' },
    fontWeight: { base: 700, lg: 400 },
    pr: { base: '1.5rem', lg: '2rem' },
    fontSize: '0.875rem',
    minHeight: { base: '0.875rem', lg: '1.75rem' },
    py: { base: '0.5rem', lg: 0 },
  },
})
