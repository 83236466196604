import useLayout from '../../../hooks/useLayout'
import { SideContentContainer } from './Styles'

interface ISideContentProps {
  children?: React.ReactNode
  showOnMobile?: boolean
}

export function SideContent({
  children,
  showOnMobile = false,
}: ISideContentProps) {
  const isLargeView = useLayout()

  if (isLargeView || showOnMobile) {
    return <SideContentContainer>{children}</SideContentContainer>
  }

  return null
}
