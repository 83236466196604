import { Button } from '@chakra-ui/button'
import { Flex, Spacer, Text } from '@chakra-ui/layout'
import { AppRoute } from '../../../routes'
import { Link as ReactLink, useNavigate } from 'react-router-dom'
import { TFunction } from 'i18next'
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  HamburgerIcon,
} from '@chakra-ui/icons'
import { MobileContainer, MobileNavButton, MobileNavLink } from './Styles'
import useMobileLayout from '../../../hooks/useMobileLayout'

export default function NavigationButtons({
  t,
  pageNumber,
  areaIndex,
  numberOfAreas,
  areaLength,
  isFinished,
}: {
  t: TFunction
  pageNumber: number
  areaIndex: number
  numberOfAreas: number
  areaLength: number
  isFinished: boolean
}) {
  const isMobile = useMobileLayout()
  const navigate = useNavigate()

  if (isMobile) {
    return (
      <MobileContainer>
        <MobileNavLink
          data-cy="return-to-areas-mobile"
          to={AppRoute.MAIN_PAGE}
          aria-label={t('areapage.buttons.area') ?? undefined}
        >
          <HamburgerIcon />
        </MobileNavLink>
        <Spacer />
        <MobileNavButton
          data-cy="previous-step-mobile"
          variant="outline"
          isDisabled={pageNumber <= 1}
          aria-label={t('areapage.buttons.previous')}
          icon={<ArrowBackIcon />}
          onClick={() => {
            navigate(pageNumber <= 1 ? {} : AppRoute.AREA_PAGE + areaIndex)
          }}
        />
        <Text whiteSpace="nowrap">
          {pageNumber} / {areaLength}
        </Text>
        {pageNumber >= numberOfAreas ? (
          <MobileNavLink
            data-cy={
              isFinished ? 'survey-finished-mobile' : 'survey-next-mobile'
            }
            aria-label={
              (isFinished
                ? t('areapage.buttons.finished')
                : t('areapage.buttons.next')) ?? undefined
            }
            to={AppRoute.MAIN_PAGE}
          >
            <ArrowForwardIcon />
          </MobileNavLink>
        ) : (
          <MobileNavButton
            data-cy="next-step"
            aria-label={t('areapage.buttons.back')}
            icon={<ArrowForwardIcon />}
            onClick={() => {
              navigate(AppRoute.AREA_PAGE + (pageNumber + 1))
            }}
          />
        )}
      </MobileContainer>
    )
  }

  return (
    <Flex my="2.5rem" gap="1.25rem" alignItems="center">
      <Button
        as={ReactLink}
        to={AppRoute.MAIN_PAGE}
        data-cy="return-to-areas"
        variant="outline"
        onClick={() => {
          navigate(AppRoute.MAIN_PAGE)
        }}
      >
        {t('areapage.buttons.area')}
      </Button>
      <Spacer />
      <Button
        as={ReactLink}
        to={pageNumber <= 1 ? {} : AppRoute.AREA_PAGE + areaIndex}
        isDisabled={pageNumber <= 1}
        variant="outline"
      >
        {t('areapage.buttons.previous')}
      </Button>
      <Text whiteSpace="nowrap">
        {pageNumber} / {areaLength}
      </Text>
      {pageNumber >= numberOfAreas ? (
        <Button as={ReactLink} to={AppRoute.MAIN_PAGE}>
          {isFinished
            ? t('areapage.buttons.finished')
            : t('areapage.buttons.back')}
        </Button>
      ) : (
        <Button as={ReactLink} to={AppRoute.AREA_PAGE + (pageNumber + 1)}>
          {t('areapage.buttons.next')}
        </Button>
      )}
    </Flex>
  )
}
