import { Button, IconButton } from '@chakra-ui/button'
import { Spinner } from '@chakra-ui/react'
import { pdf } from '@react-pdf/renderer'
import { TFunction } from 'i18next'
import { useContext, useState } from 'react'
import { JustFoodContext } from '../../../store'
import { FaFileDownload } from 'react-icons/fa'
import useJFToast from '../../../hooks/useJFToast'
import { ISettings } from '../../types'
import { PdfGenerator } from './PdfGenerator'
import { useParseSnapshot } from '../hooks/useParseSnapshotLogic'
import { ISummaryView } from './SummaryChart'

export default function PdfDownloadLinkElement({
  isMobileView,
  t,
}: {
  isMobileView: boolean
  t: TFunction
}) {
  const { userContext } = useContext(JustFoodContext)
  const summary = useParseSnapshot()
  const fileName = t('pdf.fileName')
  const [pdfLoading, setPdfLoading] = useState(false)
  const jfToast = useJFToast()
  const pdfErroroastId = 'pdfError'

  const generatePdfDocument = async (
    data: ISettings,
    summary: ISummaryView
  ) => {
    try {
      setPdfLoading(true)
      const blob = await pdf(
        <PdfGenerator data={data} summary={summary} />
      ).toBlob()
      let fileURL = window.URL.createObjectURL(blob)
      let tempDownloadLink = document.createElement('a')
      tempDownloadLink.href = fileURL
      tempDownloadLink.setAttribute('download', fileName)
      tempDownloadLink.click()
      setPdfLoading(false)
    } catch (err) {
      jfToast(
        pdfErroroastId,
        'error',
        'mainpage.toasts.downloadFailedTitle',
        'mainpage.toasts.downloadFailedDescription'
      )
      setPdfLoading(false)
    }
  }

  if (pdfLoading) {
    return isMobileView ? (
      <IconButton
        aria-label={t('mainpage.downloadInProgress')}
        icon={<Spinner />}
        isDisabled
      />
    ) : (
      <Button isDisabled w="100%">
        {t('mainpage.downloadInProgress')}
      </Button>
    )
  }

  return isMobileView ? (
    <IconButton
      onClick={() => generatePdfDocument(userContext, summary as ISummaryView)}
      aria-label={t('mainpage.downloadDocument')}
      icon={<FaFileDownload />}
    />
  ) : (
    <Button
      onClick={() => generatePdfDocument(userContext, summary as ISummaryView)}
      w="100%"
    >
      {t('mainpage.downloadDocument')}
    </Button>
  )
}
