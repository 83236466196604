import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { JustFoodContext } from '../../../store'
import { useJustFoodApiDelete } from '../../justFoodApi'
import { ISettings } from '../../types'

export default function useDeleteButtonLogic() {
  const navigate = useNavigate()
  const { userContext, updateUserContext } = useContext(JustFoodContext)

  const deleteMutation = useJustFoodApiDelete()

  const handleDelete = async () => {
    if (userContext.link) {
      await deleteMutation.mutateAsync({
        link: userContext.link,
        password: userContext.password,
      })
    }
    const ctx: ISettings = {
      organizationType: undefined,
      link: undefined,
      password: undefined,
      code: undefined,
      areas: [],
    }
    updateUserContext(ctx)
    navigate('/', { replace: true })
  }
  return { handleDelete, linkExists: !!userContext.link }
}
