import { useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { SaveButton } from './SaveButton'
import { ShareButton } from './ShareButton'
import { ActionButtonGrid } from './Styles'
import useMobileLayout from '../../../hooks/useMobileLayout'
import PdfDownloadLinkElement from './PdfDownloadLinkElement'
import { DeleteButton } from './DeleteButton'
import { RestartButton } from './RestartButton'

export function ActionButtons() {
  const { t } = useTranslation()
  const isMobileView = !!useMobileLayout()
  const isSmallestView = useBreakpointValue({
    base: true,
    sm: false,
  })

  return (
    <ActionButtonGrid>
      <ShareButton showOnlyIcon={!!isSmallestView} />
      <PdfDownloadLinkElement isMobileView={isMobileView} t={t} />
      <RestartButton showOnlyIcon={isMobileView} />
      <SaveButton showOnlyIcon={isMobileView} />
      <DeleteButton showOnlyIcon={isMobileView} />
    </ActionButtonGrid>
  )
}
