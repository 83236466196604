import {
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Button,
  Flex,
  IconButton,
  Spinner,
  Center,
  Heading,
} from '@chakra-ui/react'
import { IoMdShareAlt } from 'react-icons/io'
import useShareButtonLogic from '../hooks/useShareButtonLogic'
import { ShareModalButton, ShareModalFooter } from './Styles'
import { isPasswordInvalid } from '../../utils/helpers'

interface IShareButtonProps {
  showOnlyIcon: boolean
}

export function ShareButton(props: IShareButtonProps) {
  const { showOnlyIcon } = props
  const shareButtonLogic = useShareButtonLogic()
  const { linkExists, isOpen, onClose, handleOpenModal, t } = shareButtonLogic

  return (
    <>
      {showOnlyIcon ? (
        <IconButton
          data-cy="share-form"
          aria-label={t('mainpage.share')}
          onClick={handleOpenModal}
          icon={<IoMdShareAlt />}
        />
      ) : (
        <Button data-cy="share-form" onClick={handleOpenModal} w="100%">
          {t('mainpage.share')}
        </Button>
      )}
      <Modal
        scrollBehavior="inside"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        size={{ base: 'sm', sm: 'md', md: '2xl', lg: '4xl' }}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          data-cy="share-link-modal"
          pb="0.5rem"
          overflowY="auto"
          fontSize={{ base: '1rem', md: '1.125rem' }}
        >
          <ModalHeader>
            <Heading fontSize="lg" pt="1rem">
              {linkExists
                ? t('shareSurvey.linkFormed.title')
                : t('shareSurvey.formLink.title')}
            </Heading>
          </ModalHeader>
          <ShareModalContent {...shareButtonLogic} />
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  )
}

function ShareModalContent(props: ReturnType<typeof useShareButtonLogic>) {
  const {
    userContext,
    queriesLoading,
    linkExists,
    onClose,
    handleLinkDeletion,
    handleCopyLink,
    handleShare,
    getRouteString,
    placeholderTranslation,
    t,
    passwordString,
    setPasswordString,
  } = props

  const InputPatternTitle = t('mainpage.validpassword')

  if (queriesLoading) {
    return (
      <ModalBody>
        <Center h="8rem">
          <Spinner size="xl" />
        </Center>
      </ModalBody>
    )
  }

  if (linkExists) {
    const placeholderCopytext = t('shareSurvey.linkFormed.copyLinkButton')
    const placeholderDelete = t('shareSurvey.linkFormed.deleteLinkButton')
    return (
      <>
        <ModalBody>
          <Text>{t('shareSurvey.linkFormed.text')}</Text>
          <Text
            data-cy="share-link"
            ml={'auto'}
            my={{ base: '1rem', md: '2rem' }}
            color="#1070C9"
          >
            {getRouteString()}
          </Text>
          {passwordString && (
            <Box>
              <Text data-cy="share-link-password">
                {t('shareSurvey.linkFormed.password')}: {passwordString}
              </Text>
              <Text fontSize="sm">{t('shareSurvey.linkFormed.note')}</Text>
            </Box>
          )}
        </ModalBody>
        <ShareModalFooter>
          <ShareModalButton
            variant="outline"
            onClick={handleLinkDeletion}
            aria-label={placeholderDelete}
            data-cy="delete-link"
          >
            {t('shareSurvey.linkFormed.deleteLinkButton')}
          </ShareModalButton>
          <ShareModalButton
            onClick={handleCopyLink}
            aria-label={placeholderCopytext}
            data-cy="copy-link"
          >
            {passwordString !== null
              ? t('shareSurvey.linkFormed.copyLinkAndPasswordButton')
              : t('shareSurvey.linkFormed.copyLinkButton')}
          </ShareModalButton>
        </ShareModalFooter>
      </>
    )
  }

  return (
    <>
      <ModalBody>
        <Flex width="100%" flexDir="column" justifyContent="flex-start">
          <Text>{t('shareSurvey.formLink.text')}</Text>
          <Input
            title={InputPatternTitle}
            data-cy="link-password-input"
            isInvalid={isPasswordInvalid(passwordString)}
            mt={{ base: '1rem', md: '1.5rem' }}
            maxW={{ base: '100%', sm: '90%', md: '50%', lg: '25%' }}
            value={passwordString ?? ''}
            onChange={(event) => {
              setPasswordString(event.target.value)
              const b64 = btoa(encodeURIComponent(event.target.value))
              userContext.password = b64
            }}
            placeholder={placeholderTranslation}
            aria-label={placeholderTranslation}
            onKeyUp={(e) =>
              !isPasswordInvalid(passwordString) &&
              e.key === 'Enter' &&
              handleShare()
            }
            maxLength={20}
          ></Input>
        </Flex>
        <Text mt={6} fontSize="sm">
          {t('shareSurvey.formLink.tooltip')}
        </Text>
      </ModalBody>
      <ShareModalFooter pt={{ base: '1rem', md: '2rem' }}>
        <ShareModalButton
          data-cy="cancel-create-link"
          variant="outline"
          onClick={onClose}
          aria-label="Cancel"
        >
          {t('shareSurvey.formLink.cancelButton')}
        </ShareModalButton>
        <ShareModalButton
          data-cy="create-link"
          isDisabled={isPasswordInvalid(passwordString)}
          onClick={handleShare}
          aria-label="Create link"
        >
          {t('shareSurvey.formLink.createLinkButton')}
        </ShareModalButton>
      </ShareModalFooter>
    </>
  )
}
