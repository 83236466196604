import { accordionAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys)

const baseStyle = definePartsStyle({})

const minimal = definePartsStyle({
  root: {
    borderBottomWidth: 0,
  },
  container: {
    borderTopWidth: 0,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ':last-of-type': {
      borderBottomWidth: 0,
    },
  },
  button: {
    _hover: {
      background: 'inherit',
    },
  },
})

export const accordionTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { minimal },
})
