import React, { useState, useEffect, createContext } from 'react'
import {
  COMPANY_AREA_COUNTS,
  PUBLIC_AREA_COUNTS,
  FOODSERVICES_AREA_COUNTS,
  ORGANIZATIONS_AREA_COUNTS,
} from '../config'
import { IJustFood, ISettings, OrganizationType } from '../features/types'

const LOCALSTORAGE_USERDATA_KEY = 'justfood_store'

type IContextType = {
  userContext: ISettings
  resetUserContext: () => void
  updateUserContext: (ctx: ISettings) => void
}

const InitialUserContext: ISettings = {
  organizationType: undefined,
  link: undefined,
  code: undefined,
  password: undefined,
  areas: [],
  operator: undefined,
  timeframe: undefined,
}

const InitialContext: IContextType = {
  userContext: InitialUserContext,
  resetUserContext: () => {},
  updateUserContext: (ctx: ISettings) => {},
}
const JustFoodContext = createContext<IContextType>(InitialContext)

let first = true
const ContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [userContext, setUserContext] = useState<ISettings>(InitialUserContext)

  useEffect(() => {
    if (first) {
      const usersData =
        JSON.parse(localStorage.getItem(LOCALSTORAGE_USERDATA_KEY) as string) ||
        InitialUserContext
      if (usersData) {
        setUserContext(usersData)
      }
    }
    first = false
  }, [])

  useEffect(() => {
    localStorage.setItem(LOCALSTORAGE_USERDATA_KEY, JSON.stringify(userContext))
  }, [userContext])

  function resetUserContext() {
    localStorage.removeItem(LOCALSTORAGE_USERDATA_KEY)
    setUserContext({
      organizationType: undefined,
      link: undefined,
      code: undefined,
      password: undefined,
      areas: [],
      operator: undefined,
      timeframe: undefined,
      freecomment: undefined,
    })
  }

  function updateUserContext(ctx: ISettings) {
    setUserContext(ctx)
  }

  return (
    <JustFoodContext.Provider
      value={{
        userContext,
        resetUserContext,
        updateUserContext,
      }}
    >
      {children}
    </JustFoodContext.Provider>
  )
}

export const staticContext: IJustFood = {
  organizations: [
    {
      organizationType: OrganizationType.COMPANY,
      areaQuestionCounts: COMPANY_AREA_COUNTS,
    },
    {
      organizationType: OrganizationType.PUBLIC,
      areaQuestionCounts: PUBLIC_AREA_COUNTS,
    },
    {
      organizationType: OrganizationType.FOODSERVICES,
      areaQuestionCounts: FOODSERVICES_AREA_COUNTS,
    },
    {
      organizationType: OrganizationType.ORGANIZATIONS,
      areaQuestionCounts: ORGANIZATIONS_AREA_COUNTS,
    },
  ],
}

export { ContextProvider, JustFoodContext }
