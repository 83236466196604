import { useTranslation } from 'react-i18next'
import { ROUTE_BASE_PATH } from '../../../config'

export default function () {
  const { t } = useTranslation()

  return {
    reloadPage: function () {
      window.location.reload()
    },
    navigateToRoot: function () {
      window.location.href = window.location.origin + ROUTE_BASE_PATH
    },
    t,
  }
}
