import { ChakraProvider } from '@chakra-ui/react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import FrontPage from './features/frontPage'
import LinkPage from './features/linkPage'
import MainPage from './features/mainPage'
import { AppRoute } from './routes'
import Fonts from './theme/fonts'
import theme from './theme'
import { ROUTE_BASE_PATH } from './config'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AreaPage } from './features/areaPage'
import LinkPagePassword from './features/linkPagePassword'
import { GlobalErrorBoundary } from './features/globalErrorBoundary'
import { ContextProvider } from './store'
import CommentPage from './features/commentPage'

// Note:
// Cache is disabled as we need to rely on always getting fresh data from db.
// Retry changed to 1 to fail faster. No need to retry several times.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      retry: 1,
    },
  },
})

export const App = () => (
  <ChakraProvider theme={theme}>
    <Fonts />
    <GlobalErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <BrowserRouter basename={ROUTE_BASE_PATH}>
            <Routes>
              <Route path={AppRoute.ROOT} element={<FrontPage />} />
              <Route path={AppRoute.FRONT_PAGE} element={<FrontPage />} />
              <Route path={AppRoute.MAIN_PAGE} element={<MainPage />} />
              <Route path={AppRoute.COMMENT_PAGE} element={<CommentPage />} />
              <Route path={AppRoute.AREA_PAGE + ':id'} element={<AreaPage />} />
              <Route
                path={AppRoute.LINK_PAGE + ':link'}
                element={<LinkPage />}
              />
              <Route
                path={AppRoute.LINK_PAGE_PASSWORD + ':link'}
                element={<LinkPagePassword />}
              />
            </Routes>
          </BrowserRouter>
        </ContextProvider>
      </QueryClientProvider>
    </GlobalErrorBoundary>
  </ChakraProvider>
)
