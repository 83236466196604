import { useBreakpointValue } from '@chakra-ui/react'

/**
 * Hook for deciding the page layout
 *
 * @returns true if large viewport (> 991 px), false if small
 */
export default function () {
  return useBreakpointValue({
    base: false,
    lg: true,
  })
}
