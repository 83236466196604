import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  DeleteJustFoodCommand,
  JustFoodDTO,
  CreateJustFoodCommand,
  UpdateJustFoodCommand,
} from '../../../api/justfood'
import useJustFoodApi from '../../../api/useJustFoodApi'
import {
  deleteJustFood,
  getJustFood,
  getJustFoodPassword,
  postJustFood,
  putJustFood,
} from '../api'

export interface IJustFoodGet {
  link: string | null | undefined
  password?: string | null
}

export function useJustFoodApiGet(
  request: IJustFoodGet | null,
  enabled: boolean = true,
  onSuccess?: (responseData: JustFoodDTO) => void,
  onError?: (err: any) => void
) {
  const queryEnabled = enabled && request?.link != null
  const justFoodApi = useJustFoodApi()
  const query = useQuery(
    ['GET', request],
    () =>
      getJustFood(justFoodApi, {
        apiVersion: '1',
        link: request!.link!,
        password: request?.password ?? undefined,
      }),
    { enabled: queryEnabled, onError: (error) => onError?.(error), onSuccess }
  )
  return query
}

export function useJustFoodPasswordApiGet(
  request: IJustFoodGet | null,
  enabled: boolean = true,
  onSuccess?: (responseData: JustFoodDTO) => void,
  onError?: (err: any) => void
) {
  const queryEnabled =
    enabled && request?.link != null && request?.password != null
  const justFoodApi = useJustFoodApi()
  const query = useQuery(
    ['GET_PASSWORD', request],
    () =>
      getJustFoodPassword(justFoodApi, {
        apiVersion: '1',
        link: request!.link!,
        password: request!.password!,
      }),
    { enabled: queryEnabled, onError: (error) => onError?.(error), onSuccess }
  )
  return query
}

export function useJustFoodApiPost(args?: {
  onSuccess?: (responseData: string, commandArg: CreateJustFoodCommand) => void
  onError?: (err: any) => void
}) {
  const queryClient = useQueryClient()
  const justFoodApi = useJustFoodApi()

  const mutation = useMutation(
    (createJustFoodCommand: CreateJustFoodCommand) => {
      return postJustFood(justFoodApi, {
        apiVersion: '1',
        createJustFoodCommand: createJustFoodCommand,
      })
    },
    {
      onSuccess: (responseData, commandArg) => {
        queryClient.invalidateQueries('GET')
        args?.onSuccess?.(responseData, commandArg)
      },
      onError: (error) => args?.onError?.(error),
    }
  )
  return mutation
}

export function useJustFoodApiPut(args?: {
  onSuccess?: (responseData: string, commandArg: UpdateJustFoodCommand) => void
  onError?: (err: any) => void
}) {
  const justFoodApi = useJustFoodApi()
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (updateJustFoodCommand: UpdateJustFoodCommand) => {
      return putJustFood(justFoodApi, {
        apiVersion: '1',
        updateJustFoodCommand: updateJustFoodCommand,
        link: updateJustFoodCommand!.link!,
      })
    },
    {
      onSuccess: (responseData, commandArg) => {
        queryClient.invalidateQueries('GET')
        args?.onSuccess?.(responseData, commandArg)
      },
      onError: (error) => args?.onError?.(error),
    }
  )
  return mutation
}

export function useJustFoodApiDelete() {
  const justFoodApi = useJustFoodApi()
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (deleteJustFoodCommand: DeleteJustFoodCommand) => {
      return deleteJustFood(justFoodApi, {
        apiVersion: '1',
        deleteJustFoodCommand: deleteJustFoodCommand,
        link: deleteJustFoodCommand!.link!,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('GET')
      },
    }
  )
  return mutation
}
