/* tslint:disable */
/* eslint-disable */
/**
 * JustFood API
 * <b>Edit this text in the swaggerDescription.html</b>  <br/>
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Just Food DTO
 * @export
 * @interface JustFoodDTO
 */
export interface JustFoodDTO {
  /**
   *
   * @type {string}
   * @memberof JustFoodDTO
   */
  content?: string
  /**
   *
   * @type {string}
   * @memberof JustFoodDTO
   */
  link?: string
  /**
   *
   * @type {string}
   * @memberof JustFoodDTO
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof JustFoodDTO
   */
  password?: string | null
}

export function JustFoodDTOFromJSON(json: any): JustFoodDTO {
  return JustFoodDTOFromJSONTyped(json, false)
}

export function JustFoodDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): JustFoodDTO {
  if (json === undefined || json === null) {
    return json
  }
  return {
    content: !exists(json, 'content') ? undefined : json['content'],
    link: !exists(json, 'link') ? undefined : json['link'],
    code: !exists(json, 'code') ? undefined : json['code'],
    password: !exists(json, 'password') ? undefined : json['password'],
  }
}

export function JustFoodDTOToJSON(value?: JustFoodDTO | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    content: value.content,
    link: value.link,
    code: value.code,
    password: value.password,
  }
}
