import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface IOptionalInformationHeader {
  operator: string | undefined
  timeframe: string | undefined
}

export default function OptionalInformationHeader({
  operator,
  timeframe,
}: IOptionalInformationHeader) {
  const { t } = useTranslation()
  return timeframe || operator ? (
    <Box mb={10}>
      {operator && (
        <Grid templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Text as="b">{t('mainpage.programoroperator')}</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Box>{operator}</Box>
          </GridItem>
        </Grid>
      )}
      {timeframe && (
        <Grid templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Text as="b">{t('mainpage.timeframe')}</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Box>{timeframe}</Box>
          </GridItem>
        </Grid>
      )}
    </Box>
  ) : (
    <></>
  )
}
