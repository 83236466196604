import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton,
  SimpleGrid,
} from '@chakra-ui/react'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaUndoAlt } from 'react-icons/fa'
import useRestartButtonLogic from '../hooks/useRestartButtonLogic'

interface IRestartButtonProps {
  showOnlyIcon: boolean
}

export const RestartButton = ({ showOnlyIcon }: IRestartButtonProps) => {
  const { t } = useTranslation()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const cancelRef = React.useRef(null)

  const { handleRestart } = useRestartButtonLogic()

  return (
    <>
      {showOnlyIcon ? (
        <IconButton
          data-cy="restart-form-data"
          aria-label={t('mainpage.restart')}
          onClick={onOpen}
          icon={<FaUndoAlt />}
        />
      ) : (
        <Button data-cy="restart-form-data" w="100%" onClick={onOpen}>
          {t('mainpage.restart')}
        </Button>
      )}
      <AlertDialog
        size={'lg'}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent w={'full'}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('mainpage.restarttitle')}
            </AlertDialogHeader>
            <AlertDialogBody>
              <Trans t={t}>{'mainpage.restartdescription'}</Trans>
            </AlertDialogBody>
            <AlertDialogFooter>
              <SimpleGrid columns={showOnlyIcon ? 1 : 2} spacing={3}>
                <Button
                  variant="outline"
                  ref={cancelRef}
                  onClick={onClose}
                  data-cy="cancel-restart-data"
                >
                  {t('mainpage.cancel')}
                </Button>
                <Button
                  bg={'brand.warning'}
                  onClick={handleRestart}
                  data-cy="confirm-restart-data"
                >
                  {t('mainpage.restart')}
                </Button>
              </SimpleGrid>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
