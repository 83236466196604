import { Box, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import useMobileLayout from '../../../hooks/useMobileLayout'
import useLanguageViewLogic from '../hooks/useLanguageSelectLogic'

export default function ({
  otherLanguages,
  changeLanguage,
}: ReturnType<typeof useLanguageViewLogic>) {
  const { t } = useTranslation()
  const isMobileView = useMobileLayout()

  return (
    <Flex gap={4}>
      {otherLanguages.map(({ id }) => {
        const label = t('languageselect.' + id)
        return (
          <Box
            key={id}
            as="button"
            role="button"
            fontWeight="700"
            fontSize="0.875rem"
            aria-label={label}
            onClick={() => changeLanguage(id)}
          >
            {isMobileView
              ? t(`languageselectShort.${id}`)
              : t(`languageselect.${id}`)}
          </Box>
        )
      })}
    </Flex>
  )
}
