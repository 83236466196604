import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useContext, useEffect } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { AppRoute } from '../../../routes'
import { OrganizationType } from '../../types'
import { Trans, useTranslation } from 'react-i18next'
import {
  PageTemplate,
  InfoBar,
  Main,
  SideContent,
  HideOnMobile,
} from '../../pageTemplate'
import useFrontPageLogic from '../hooks/useFrontPageLogic'
import { CheckmarkRadio } from '../../../components/checkmarkRadio'
import { JustFoodContext } from '../../../store'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import useBackCheck from '../../../hooks/useBackCheck'

export default function FrontPageView({
  handleStartButton,
  handleOrganizationTypeChange,
  handleOperatorChange,
  handleTimeframeChange,
}: ReturnType<typeof useFrontPageLogic>) {
  const { t } = useTranslation()
  useBackCheck()
  const { userContext, resetUserContext } = useContext(JustFoodContext)

  const programOrOperatorText = t('frontpage.programoroperator')
  const timeFrameText = t('frontpage.timeframe')

  useEffect(() => {
    // Reset user state and remove link if we are coming back to front page
    if (
      userContext.organizationType !== undefined &&
      (userContext.areas.length > 0 || userContext.link !== undefined)
    ) {
      if (userContext.link) {
        // TODO: Do we really want this?
        /* deleteMutation.mutate({
          link: userContext.link,
        }) */
      }
      resetUserContext()
    }
  }, [userContext, resetUserContext])

  return (
    <PageTemplate>
      <InfoBar showBackgroundImage />
      <Main>
        {/* Page specific center panel content */}
        <Flex flexDir="column" justifyContent={{ lg: 'center' }} h="100%">
          <HideOnMobile>
            <Heading as="h1" textStyle="h1" mb="1.75rem">
              {t('frontpage.title')}
            </Heading>
          </HideOnMobile>
          <Text mb="1.75rem">
            <Trans t={t}>{'frontpage.description'}</Trans>
          </Text>
          <ReactLink to="/documents/justfood/Kayttoohjeet.pdf" target="_blank">
            <Text as="b">
              {t('frontpage.userdocument')} <ExternalLinkIcon />
            </Text>
          </ReactLink>
          <Box mt="1.75rem">
            <Text fontWeight={'bold'}>{t('frontpage.radiolabel')}</Text>
            <RadioGroup
              my={5}
              onChange={(value) => {
                handleOrganizationTypeChange(value)
              }}
              value={userContext.organizationType || undefined}
            >
              <Stack>
                <CheckmarkRadio
                  value={OrganizationType.COMPANY}
                  variant="organization"
                >
                  {t(`${OrganizationType.COMPANY}.name`)}
                </CheckmarkRadio>
                <CheckmarkRadio
                  value={OrganizationType.PUBLIC}
                  variant="organization"
                >
                  {t(`${OrganizationType.PUBLIC}.name`)}
                </CheckmarkRadio>
                <CheckmarkRadio
                  value={OrganizationType.FOODSERVICES}
                  variant="organization"
                >
                  {t(`${OrganizationType.FOODSERVICES}.name`)}
                </CheckmarkRadio>
                <CheckmarkRadio
                  value={OrganizationType.ORGANIZATIONS}
                  variant="organization"
                >
                  {t(`${OrganizationType.ORGANIZATIONS}.name`)}
                </CheckmarkRadio>
              </Stack>
            </RadioGroup>
            <SimpleGrid columns={1} gap={3} maxW={300} my={8}>
              <Text fontWeight={'bold'}>
                {t('frontpage.optionalinformation')}
              </Text>
              <Input
                data-cy="optionalOperator"
                value={userContext.operator || ''}
                placeholder={programOrOperatorText}
                maxLength={100}
                onChange={(event) => {
                  handleOperatorChange(event.currentTarget.value)
                }}
              />
              <Input
                data-cy="optionalTimeframe"
                value={userContext.timeframe || ''}
                placeholder={timeFrameText}
                maxLength={25}
                onChange={(event) => {
                  handleTimeframeChange(event.currentTarget.value)
                }}
              />
            </SimpleGrid>
            <Button
              as={ReactLink}
              to={AppRoute.MAIN_PAGE}
              isDisabled={userContext.organizationType === undefined}
              onClick={() => handleStartButton()}
            >
              {t('frontpage.startbutton')}
            </Button>
          </Box>
        </Flex>
      </Main>
      <SideContent />
    </PageTemplate>
  )
}
