import { useDisclosure } from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { JustFoodDTO } from '../../../api/justfood'
import { ROUTE_BASE_PATH } from '../../../config'
import { JustFoodContext } from '../../../store'
import useJFToast from '../../../hooks/useJFToast'
import {
  useJustFoodApiGet,
  useJustFoodApiPost,
  useJustFoodApiDelete,
} from '../../justFoodApi'
import { IJustFoodGet } from '../../justFoodApi/hooks/useJustFoodLogic'
import useMobileLayout from '../../../hooks/useMobileLayout'

export default function useShareButtonLogic() {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const jfToast = useJFToast()
  const { userContext, updateUserContext } = useContext(JustFoodContext)
  const isMobile = useMobileLayout()

  const [passwordString, setPasswordString] = useState<string | null>(null)

  const [getData, setGetData] = useState<IJustFoodGet | null>(null)
  const [getDataEnabled, setGetDataEnabled] = useState<boolean>(false)

  const onGetLinkSuccess = (data: JustFoodDTO) => {
    setGetData(null)
    setGetDataEnabled(false)
  }

  const onGetError = (error: any) => {
    const ctx = {
      ...userContext,
      link: undefined,
      code: undefined,
      password: undefined,
    }
    setGetDataEnabled(false)
    updateUserContext(ctx)

    jfToast(
      'getLinkError',
      'warning',
      'mainpage.toasts.getlinkerrortitle',
      'mainpage.toasts.getlinkerrordescription'
    )
  }

  const getRequest = useJustFoodApiGet(
    getData,
    getDataEnabled,
    onGetLinkSuccess,
    onGetError
  )
  const saveDataMutation = useJustFoodApiPost()
  const deleteLinkMutation = useJustFoodApiDelete()

  const queries = [getRequest, saveDataMutation, deleteLinkMutation]
  const anyQueriesLoading = queries.some((q) => q.isLoading)

  const handleOpenModal = () => {
    // If we have existing link we need verify that it still exists
    // before opening the modal
    // userSnap.link === undefined ? onOpen() : setGetData(userSnap.link)
    if (userContext.link !== undefined) {
      setGetData({ link: userContext.link, password: userContext.password })
      setGetDataEnabled(true)
    }
    onOpen()
  }

  const handleLinkDeletion = async () => {
    try {
      await deleteLinkMutation.mutateAsync({
        link: userContext.link,
        password: userContext.password,
      })
      const ctx = {
        ...userContext,
        link: undefined,
        password: undefined,
        code: undefined,
      }
      updateUserContext(ctx)
      setPasswordString(null)
      jfToast(
        'deleteLinkSuccess',
        'success',
        'mainpage.toasts.deletesuccesstitle',
        'mainpage.toasts.deletesuccessdescription'
      )
    } catch (err) {
      jfToast(
        'deleteLinkError',
        'error',
        'mainpage.toasts.deleteerrortitle',
        'mainpage.toasts.deleteerrordescription'
      )
    }
  }

  const handleCopyLink = () => {
    const copyText = passwordString
      ? `Link: ${getRouteString()} Password: ${passwordString}`
      : getRouteString()
    navigator.clipboard.writeText(copyText)
    jfToast(
      'copySuccess',
      'success',
      'mainpage.toasts.copysuccesstitle',
      'mainpage.toasts.copysuccessdescription'
    )
    onClose()
  }

  const handleShare = async () => {
    try {
      const val = Date.now().toString()
      const postData = {
        link: val,
        code: val,
        password: userContext.password,
        content: JSON.stringify(userContext),
      }
      const resp = await saveDataMutation.mutateAsync(postData)
      const ctx = {
        ...userContext,
        link: postData?.link,
        code: postData?.code!,
        password: resp.length > 0 ? resp : undefined,
      }
      // Resp may be empty string but not null
      updateUserContext(ctx)
      jfToast(
        'shareSuccess',
        'success',
        'mainpage.toasts.sharesuccesstitle',
        'mainpage.toasts.sharesuccessdescription'
      )
    } catch (err) {
      const ctx = {
        ...userContext,
        link: undefined,
        password: undefined,
        code: undefined,
      }
      updateUserContext(ctx)
      setPasswordString(null)
      jfToast(
        'saveError',
        'error',
        'mainpage.toasts.shareerrortitle',
        'mainpage.toasts.shareerrordescription'
      )
    }
  }

  const getRouteString = () =>
    origin +
    `${ROUTE_BASE_PATH}/linkpage${
      userContext.password != null ? 'password' : ''
    }/${userContext.link}`.replace(/\/\/+/g, '/')

  const placeholderTranslation = t('shareSurvey.formLink.passwordPlaceholder')

  return {
    userContext,
    queriesLoading: anyQueriesLoading,
    linkExists: !!userContext.link,
    isOpen,
    onOpen,
    onClose,
    handleOpenModal,
    handleLinkDeletion,
    handleCopyLink,
    handleShare,
    getRouteString,
    placeholderTranslation,
    passwordString,
    setPasswordString,
    t,
    isMobile,
  }
}
