import { CircularProgress } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import useLayout from '../../../hooks/useLayout'
import { AppRoute } from '../../../routes'
import { OrganizationType } from '../../types'
import {
  AreaTitle,
  AreaCompleteCheck,
  AreaCompleteIcon,
  StyledAreaLink,
} from './Styles'

export type IAreaLink = {
  areaIndex: number
  parsed: any
  type: OrganizationType | undefined
}

export function AreaLink({ areaIndex, parsed, type }: IAreaLink) {
  const { t } = useTranslation()
  const areaProgress = parsed.areaProgress?.at(areaIndex)
  const areaComplete = areaProgress === 100
  const isLargeView = useLayout()

  return (
    <StyledAreaLink
      data-cy={`area-${areaIndex + 1}`}
      to={AppRoute.AREA_PAGE + (areaIndex + 1)}
      variant={areaComplete ? 'completedArea' : 'area'}
    >
      <AreaCompleteCheck>
        {areaComplete && (
          <AreaCompleteIcon
            aria-label={t('mainpage.arealinks.areacomplete') ?? undefined}
          />
        )}
      </AreaCompleteCheck>
      <AreaTitle>{t(`${type}.area_${areaIndex + 1}.title`)}</AreaTitle>
      <CircularProgress
        color="brand.palette2"
        ml="0.5rem"
        value={areaProgress}
        size={isLargeView ? '3.325rem' : '2rem'}
        thickness={isLargeView ? '1rem' : '0.75rem'}
        visibility={areaProgress === 0 ? 'hidden' : 'visible'}
      />
    </StyledAreaLink>
  )
}
