import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export default function useBackCheck() {
  const { t } = useTranslation()
  const onBackButtonEvent = useCallback(
    (e: PopStateEvent) => {
      e.preventDefault()
      window.alert(t('errors.backButtonDisabled'))
    },
    [t]
  )

  useEffect(() => {
    window.history.pushState(null, 'null', window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [onBackButtonEvent])
}
