import { InfoIcon } from '@chakra-ui/icons'
import { Accordion, AccordionItem, Image } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import useMobileLayout from '../../../hooks/useMobileLayout'
import JustFoodIcon from '../../../theme/images/JustFoodIcon.png'

import {
  InfoBarContent,
  InfoSidebar,
  LogoContainer,
  MobileInfoSidebar,
  MobileLogoHeaderContainer,
  StyledAccordionButton,
  AccordionHeading,
  StyledAccordionPanel,
  MobilePageHeading,
  AreaImageContainer,
  BubbleBackground,
} from './Styles'
import AreaImage from '../../areaPage/components/AreaImage'

interface IInfobarProps {
  children?: React.ReactNode
  showBackgroundImage?: boolean
  collapseOnMobile?: boolean
  mobileTitle?: string | undefined
  pageNumber?: number | undefined
}

export function InfoBar({
  children,
  showBackgroundImage = false,
  collapseOnMobile = false,
  mobileTitle,
  pageNumber,
}: IInfobarProps) {
  const isMobileView = useMobileLayout()
  const { t } = useTranslation()

  if (isMobileView) {
    return (
      <MobileInfoSidebar showBackgroundImage={showBackgroundImage}>
        {collapseOnMobile ? (
          <Accordion allowToggle variant="minimal" w="100%">
            <AccordionItem>
              <StyledAccordionButton>
                <AccordionHeading>
                  {t(mobileTitle ?? 'frontpage.title')}
                </AccordionHeading>
                <InfoIcon />
              </StyledAccordionButton>
              <StyledAccordionPanel>{children}</StyledAccordionPanel>
            </AccordionItem>
          </Accordion>
        ) : (
          <MobileLogoHeaderContainer>
            <LogoContainer>
              <Image
                src={JustFoodIcon}
                minW="5rem"
                w={{ base: '5rem', sm: '5rem', md: '12vw' }}
              />
            </LogoContainer>
            <MobilePageHeading>
              {t(mobileTitle ?? 'frontpage.title')}
            </MobilePageHeading>
          </MobileLogoHeaderContainer>
        )}
      </MobileInfoSidebar>
    )
  }

  return (
    <InfoSidebar showBackgroundImage={showBackgroundImage}>
      {pageNumber !== undefined ? (
        <AreaImageContainer>
          <AreaImage w="50%" page={pageNumber} alt="" />
        </AreaImageContainer>
      ) : (
        <LogoContainer>
          <Image
            src={JustFoodIcon}
            w={{ md: '12vw', lg: '10vw' }}
            alt={'JustFood'}
          />
        </LogoContainer>
      )}
      <BubbleBackground showBackgroundImage={showBackgroundImage}>
        <InfoBarContent>{children}</InfoBarContent>
      </BubbleBackground>
    </InfoSidebar>
  )
}
