import { RefObject } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ISettings } from '../../types'
import { QuestionAreaWrapper, AreaTitle } from './Styles'
import QuestionAreaView from './QuestionAreaView'
import NavigationButtons from './NavigationButtons'
import { HideOnMobile, InfoBar, Main, PageTemplate } from '../../pageTemplate'

interface IAreaPageViewProps {
  pageNumber: number
  numberOfAreas: number
  userContext: ISettings
  updateUserContext: (ctx: ISettings) => void
  onChangeComment: (index: number, comment: string) => void
  areaIndex: number
  firstQuestionRef: RefObject<HTMLParagraphElement>
  isFinished: boolean
}

export default function AreaPageView({
  pageNumber,
  numberOfAreas,
  userContext,
  onChangeComment,
  areaIndex,
  firstQuestionRef,
  updateUserContext,
  isFinished,
}: IAreaPageViewProps) {
  const { t } = useTranslation()
  const areaTitle = `${userContext.organizationType}.area_${pageNumber}.title`

  return (
    <PageTemplate mobileHeaderColor="white" mobileHeaderBg="brand.palette1">
      {/* Left panel */}
      <InfoBar
        collapseOnMobile
        showBackgroundImage={false}
        mobileTitle={areaTitle}
        pageNumber={pageNumber}
      >
        <>
          <Trans
            i18nKey={`${userContext.organizationType}.area_${pageNumber}.description`}
          />
        </>
      </InfoBar>
      {/* Center panel */}
      {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
      <Main colSpan={{ lg: 2 }} mr={{ lg: '3rem', xl: '8rem', '2xl': '10rem' }}>
        <>
          <HideOnMobile>
            <AreaTitle>{t(areaTitle)}</AreaTitle>
          </HideOnMobile>
          <QuestionAreaWrapper>
            <p ref={firstQuestionRef} tabIndex={-1} />
            {userContext?.areas.at(areaIndex)?.answers?.map((a, i) => {
              return (
                <QuestionAreaView
                  t={t}
                  i={i}
                  key={i}
                  userContext={userContext}
                  areaIndex={areaIndex}
                  pageNumber={pageNumber}
                  organizationType={userContext.organizationType}
                  onChange={onChangeComment}
                  updateUserContext={updateUserContext}
                />
              )
            })}
          </QuestionAreaWrapper>
          <NavigationButtons
            pageNumber={pageNumber}
            areaIndex={areaIndex}
            numberOfAreas={numberOfAreas}
            areaLength={userContext.areas.length}
            t={t}
            isFinished={isFinished}
          />
        </>
      </Main>
    </PageTemplate>
  )
}
