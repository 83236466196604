import {
  Box,
  chakra,
  Flex,
  FlexProps,
  Heading,
  HeadingProps,
  IconButton,
  IconButtonProps,
  Link,
  LinkProps,
} from '@chakra-ui/react'
import { Link as RRDLink, LinkProps as RRDLinkProps } from 'react-router-dom'

export const AreaTitle = (props: HeadingProps) => {
  return (
    <Heading
      as="h1"
      textStyle="h1"
      mb={{ base: '1rem', lg: '1.5rem', xl: '2.5rem' }}
      mt={{ base: 0, xl: '1rem' }}
      fontSize={{ base: '1.5rem', lg: '2rem', xl: '2.225rem' }}
      {...props}
    />
  )
}

export const QuestionAreaWrapper = chakra(Box)

export const MetersContainer = (props: FlexProps) => {
  return (
    <Flex
      as="section"
      flexDir="column"
      mt="1rem"
      mb="2rem"
      gap="0.25rem"
      {...props}
    />
  )
}

export const MetersTitleContainer = chakra(Flex, {
  baseStyle: {
    flexDir: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.5rem',
  },
})

export const MetersLinksContainer = (props: FlexProps) => {
  return <Flex flexDir="column" gap="0.5rem" {...props} />
}

export const MobileContainer = chakra(Flex, {
  baseStyle: {
    bg: 'brand.palette4',
    pt: '1rem',
    pb: '1rem',
    px: '2.375rem',
    justifyContent: 'space-around',
    flexDir: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    mt: '1rem',
    // Following is undoing the padding added in Main component by
    // giving this container equal negative margin, because this
    // needs to extend the whole viewport width - which is an exception style-wise
    mx: {
      base: 'calc(-1 * var(--chakra-space-mxForMainContentMobile))',
      sm: 'calc(-1 * var(--chakra-space-mxForMainContent))',
    },
    mb: '-1.375rem',
  },
})

export const MobileNavLink = (props: RRDLinkProps & LinkProps) => {
  return (
    <Link
      as={RRDLink}
      variant="outline"
      border="none"
      h="2.875rem"
      w="2.875rem"
      fontSize="2.5rem"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    />
  )
}

export const MobileNavButton = (props: IconButtonProps) => {
  return (
    <IconButton
      variant="outline"
      border="none"
      h="2.875rem"
      w="2.875rem"
      fontSize="2.5rem"
      {...props}
    />
  )
}

export const QuestionWrapper = chakra(Box, {
  baseStyle: {
    mx: 0,
    mt: { base: '0.5rem', lg: '1.5rem' },
    mb: '2.5rem',
  },
})
