import {
  chakra,
  Box,
  Flex,
  Grid,
  GridItem,
  GridItemProps,
  AccordionButton,
  Heading,
  AccordionPanel,
  HeadingProps,
} from '@chakra-ui/react'
import LeftWave from '../../../theme/images/LeftWave.svg'
import Bubbles from '../../../theme/images/Bubbles.png'

export const PageContainer = chakra(Flex, {
  baseStyle: {
    position: 'relative',
    minHeight: 'pageContainer',
    flexDir: 'column',
  },
})

export const PageGrid = chakra(Grid, {
  baseStyle: {
    flex: '1 0 auto',
    gridTemplateAreas: {
      base: `
          "header"
          "info"
          "main"
          "aside"
         `,
      md: `
          "info header"
          "info main"
          "info aside"
         `,
      lg: `
          "info header header"
          "info main aside"
         `,
    },
    gridTemplateRows: {
      base: 'auto auto auto 1fr',
      md: 'auto 1fr auto',
    },
    gridTemplateColumns: {
      base: '100%',
      md: '32% 68%',
      lg: '29% 52% 19%',
      xl: '29% 46% 25%',
    },
    justifyItems: 'stretch',
  },
})

type IHeaderProps = GridItemProps & {
  mobileHeaderColor?: string
  mobileHeaderBg?: string
}

export const Header = (props: IHeaderProps) => {
  const { mobileHeaderColor, mobileHeaderBg, ...rest } = props
  return (
    <GridItem
      area="header"
      color={{
        base: mobileHeaderColor ?? 'brand.palette1',
        md: 'brand.palette1',
      }}
      bg={{
        base: mobileHeaderBg ?? 'brand.palette2',
        md: 'inherit',
      }}
      height="fit-content"
      as="header"
      display="flex"
      px={{ base: '1rem', md: '1rem' }}
      justifyContent="flex-end"
      h={{ base: '2rem', md: '3rem' }}
      w="100%"
      alignItems={{ base: 'flex-end', md: 'center' }}
      {...rest}
    />
  )
}

type IInfoSidebarProps = GridItemProps & {
  showBackgroundImage: boolean
}

export const InfoSidebar = (props: IInfoSidebarProps) => {
  const { showBackgroundImage, ...rest } = props
  return (
    <GridItem
      as="aside"
      display="flex"
      gridArea="info"
      maxW={{ md: '34rem' }}
      h={{ lg: '100%' }}
      w="100%"
      bgColor={{
        base: showBackgroundImage ? 'brand.palette2' : 'brand.palette1',
        md: 'inherit',
      }}
      color={{
        base: showBackgroundImage ? 'inherit' : 'white',
        md: 'inherit',
      }}
      bgImage={{
        md: showBackgroundImage ? `url(${LeftWave})` : '',
        lg: showBackgroundImage
          ? `url(${LeftWave}), linear-gradient(to right, #D8DE3F 30%, transparent 31%)`
          : '',
      }}
      bgSize={{
        base: 'cover',
        md: 'cover',
        lg: 'auto contain',
      }}
      backgroundClip="border-box"
      bgRepeat={{
        base: 'no-repeat no-repeat',
        md: 'no-repeat round',
      }}
      flexDir={{ base: 'row', md: 'column' }}
      bgPosition={{
        base: 'right top',
      }}
      justifyContent="flex-start"
      alignItems="center"
      pr={{ base: '1rem', xl: '3rem' }} // Tune this to position "the bubbles"
      {...rest}
    />
  )
}

export const BubbleBackground = (props: IInfoSidebarProps) => {
  const { showBackgroundImage, ...rest } = props
  return (
    <Flex
      flexDir="column"
      flex="2 1 auto"
      justifyContent="flex-start"
      alignItems="center"
      w={{ base: '100%', xl: '110%' }}
      backgroundImage={{ lg: showBackgroundImage ? `url(${Bubbles})` : 'none' }}
      bgSize="contain"
      bgRepeat="no-repeat"
      bgPos="bottom left"
      {...rest}
    />
  )
}

export const InfoBarContent = chakra(Flex, {
  baseStyle: {
    mt: { base: '3.5rem' },
    flexDir: 'column',
    gap: '1rem',
    flex: '2 1 auto',
    px: { base: '1rem', lg: 0 },
    maxW: { base: '100%', lg: '80%', xl: '75%' },
  },
})

export const LogoContainer = chakra(Box, {
  baseStyle: {
    alignSelf: { md: 'flex-start' },
    mt: { md: '1.75rem', lg: '2.5rem', xl: '5rem' },
    ml: { base: '0', md: '20%', xl: '27%' },
  },
})

export const AreaImageContainer = chakra(Box, {
  baseStyle: {
    alignSelf: { md: 'flex-start' },
    mt: { md: '1.75rem', lg: '2.5rem', xl: '5rem' },
    ml: '25%',
  },
})

export const Main = (props: GridItemProps) => {
  return (
    <GridItem
      as="main"
      area="main"
      px={{
        base: 'mxForMainContentMobile',
        sm: 'mxForMainContent',
        xl: 'mxForMainContentXL',
      }}
      py={{ base: '1.375rem' }}
      {...props}
    />
  )
}

export const SideContentContainer = (props: GridItemProps) => {
  return <GridItem as="aside" area="aside" {...props} />
}

/**
 * Mobile-specific styles
 */

export const HideOnMobile = chakra(Box, {
  baseStyle: {
    display: { base: 'none', md: 'inherit' },
  },
})

export const MobileInfoSidebar = (props: IInfoSidebarProps) => {
  const { showBackgroundImage, ...rest } = props
  return (
    <GridItem
      as="aside"
      display="flex"
      gridArea="info"
      w="100%"
      backgroundColor={
        showBackgroundImage ? 'brand.palette2' : 'brand.palette1'
      }
      color={showBackgroundImage ? 'inherit' : 'white'}
      flexDir={{ base: 'row', md: 'column' }}
      justifyContent="flex-start"
      alignItems="center"
      gap="1rem"
      {...rest}
    />
  )
}

export const MobilePageHeading = (props: HeadingProps) => {
  return (
    <Heading
      as="h1"
      fontSize={{ base: '1.5rem', sm: '1.625rem' }}
      lineHeight="1.75rem"
      color="brand.palette1"
      {...props}
    />
  )
}

// Defined separately as we don't want padding around the collapsed version
// of mobile info sidebar
export const MobileLogoHeaderContainer = chakra(Flex, {
  baseStyle: {
    position: 'relative',
    gap: { base: '1rem', sm: '2rem' },
    alignItems: 'center',
    mx: { base: 'mxForMainContentMobile', sm: 'mxForMainContent' },
    mt: 0,
    mb: { base: '2rem', md: 0 },
  },
})

export const StyledAccordionButton = chakra(AccordionButton, {
  baseStyle: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    w: '100%',
    px: { base: 'mxForMainContentMobile', sm: 'mxForMainContent' },
    pb: '1rem',
    pt: 0,
    textAlign: 'left',
  },
})

export const StyledAccordionPanel = chakra(AccordionPanel, {
  baseStyle: {
    px: { base: 'mxForMainContentMobile', sm: 'mxForMainContent' },
  },
})

export const AccordionHeading = chakra(Heading, {
  baseStyle: {
    fontSize: '1rem',
    fontWeight: '400',
  },
})
