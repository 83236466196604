import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {},
  control: {
    borderWidth: '3px',
    height: '1.5rem',
    width: '1.5rem',
  },
  label: {
    position: 'relative', // Needed to position the checkmark icon correctly
    fontSize: '1rem',
    lineHeight: '1.2rem',
    // Style applied to fix "Unable to preventDefault inside passive event listener invocation" -error
    // as suggested here: https://github.com/chakra-ui/chakra-ui/issues/2925
    pointerEvents: 'none',
    _checked: {
      fontWeight: 700,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '.check-icon': {
        color: 'white',
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '.check-icon': {
      color: 'transparent',
    },
  },
})

const variants = {
  organization: definePartsStyle({
    control: {
      borderColor: 'brand.ok',
      _checked: {
        background: 'brand.ok',
        borderColor: 'brand.ok',
        color: 'brand.ok',
        _hover: {
          bg: 'brand.ok',
        },
      },
      _hover: {
        bg: 'brand.okHover',
      },
    },
  }),
  ok: definePartsStyle({
    control: {
      borderColor: 'brand.ok',
      _checked: {
        background: 'brand.ok',
        borderColor: 'brand.ok',
        color: 'brand.ok',
        _hover: {
          bg: 'brand.ok',
        },
      },
      _hover: {
        bg: 'brand.okHover',
      },
    },
  }),
  little: definePartsStyle({
    control: {
      borderColor: 'brand.little',
      _checked: {
        background: 'brand.little',
        borderColor: 'brand.little',
        color: 'brand.little',
        _hover: {
          bg: 'brand.little',
        },
      },
      _hover: {
        bg: 'brand.littleHover',
      },
    },
  }),
  lot: definePartsStyle({
    control: {
      borderColor: 'brand.alot',
      _checked: {
        background: 'brand.alot',
        borderColor: 'brand.alot',
        color: 'brand.alot',
        _hover: {
          bg: 'brand.alot',
        },
      },
      _hover: {
        bg: 'brand.alotHover',
      },
    },
  }),
  not: definePartsStyle({
    control: {
      borderColor: 'brand.palette5',
      _checked: {
        background: 'brand.palette5',
        borderColor: 'brand.palette5',
        color: 'brand.palette5',
        _hover: {
          bg: 'brand.palette5',
        },
      },
      _hover: {
        bg: 'brand.palette5Hover',
      },
    },
  }),
}

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
})
