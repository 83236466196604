import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { ROUTE_BASE_PATH, isDevEnv, DEFAULT_LANGUAGE } from './config'

i18n
  .use(Backend) // translation files in public/locales folder
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: isDevEnv() ? true : false,
    lng: DEFAULT_LANGUAGE, // change default lang in the .env-file or by setting envrionment variables. If you're using a language detector, do not define the lng option
    fallbackLng: 'fi', // it's probably a good idea that this fallback language (which is used when keys are missing) is one of the typed languages defined in react-i18next.d.ts
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: `${getAppPath()}/locales/{{lng}}/translation.json`,
    },
    // These two are needed to parse arrays from translation files
    returnObjects: true,
    joinArrays: ';',
  })

export default i18n

function getAppPath() {
  if (ROUTE_BASE_PATH.trim() === '/') {
    return ''
  }
  return ROUTE_BASE_PATH
}
