import { useDisclosure } from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { JustFoodDTO, UpdateJustFoodCommand } from '../../../api/justfood'
import { useJustFoodApiGet, useJustFoodApiPut } from '../../justFoodApi'
import { ISettings } from '../../types'
import useJFToast from '../../../hooks/useJFToast'
import { IJustFoodGet } from '../../justFoodApi/hooks/useJustFoodLogic'
import { JustFoodContext } from '../../../store'

export default function useSaveButtonLogic() {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { t } = useTranslation()
  const { userContext, updateUserContext } = useContext(JustFoodContext)
  const jfToast = useJFToast()

  const [getData, setGetData] = useState<IJustFoodGet | null>(null)
  const [getDataEnabled, setGetDataEnabled] = useState<boolean>(false)

  const onGetSuccess = (data: JustFoodDTO) => {
    setGetDataEnabled(false)
    setGetData(null)

    const content: ISettings = JSON.parse(data.content!)
    const ctx: ISettings = {
      link: data.link,
      code: data.code,
      password: content.password,
      areas: content.areas,
      organizationType: content.organizationType,
      operator: content.operator,
      timeframe: content.timeframe,
      freecomment: content.freecomment,
    }
    updateUserContext(ctx)
    onClose()
    jfToast('getDataSuccess', 'success', 'mainpage.toasts.updatesuccesstitle')
  }

  const onGetError = (error: any) => {
    setGetDataEnabled(false)
    setGetData(null) // Why?
    const ctx: ISettings = {
      ...userContext,
      code: undefined,
      password: undefined,
      link: undefined,
    }
    updateUserContext(ctx)
    jfToast('getErrorToast', 'error', 'mainpage.toasts.getnewdataerrortitle')
    onClose()
  }

  const getRequest = useJustFoodApiGet(
    getData,
    getDataEnabled,
    onGetSuccess,
    onGetError
  )

  const onPutDataSuccess = (
    data: string,
    commandArg: UpdateJustFoodCommand
  ) => {
    const code = data.replaceAll('"', '')
    if (code.length === 0) {
      onOpen()
    } else {
      const ctx = { ...userContext, code: code }
      updateUserContext(ctx)
      jfToast(
        'saveSuccess',
        'success',
        'mainpage.toasts.savesuccesstitle',
        'mainpage.toasts.savesuccessdescription'
      )
      onClose()
    }
  }

  const putDataMutation = useJustFoodApiPut({ onSuccess: onPutDataSuccess })

  const handleSave = async () => {
    try {
      await putDataMutation.mutateAsync({
        link: userContext.link,
        password: userContext.password,
        content: JSON.stringify(userContext),
        code: userContext.code,
      })
    } catch (err) {
      const resp = err as unknown as Response
      switch (resp?.status) {
        case 404:
          const ctx = {
            ...userContext,
            link: undefined,
            code: undefined,
            password: undefined,
          }
          updateUserContext(ctx)
          jfToast(
            'saveErrorToast',
            'error',
            'mainpage.toasts.savelinkdeletederrortitle',
            'mainpage.toasts.savelinkdeletederrordescription'
          )
          break
        default:
          jfToast(
            'saveErrorToast',
            'error',
            'mainpage.toasts.saveerrortitle',
            'mainpage.toasts.saveerrordescription'
          )
      }
    }
  }

  const handleOverwrite = async () => {
    try {
      await putDataMutation.mutateAsync({
        link: userContext.link,
        password: userContext.password,
        content: JSON.stringify(userContext),
      })
    } catch (err) {
      jfToast(
        'saveErrorToast',
        'error',
        'mainpage.toasts.saveerrortitle',
        'mainpage.toasts.saveerrordescription'
      )
    }
  }

  const handleReload = () => {
    setGetData({ link: userContext.link, password: userContext.password })
    setGetDataEnabled(true)
  }

  return {
    linkExists: !!userContext.link,
    loadingData: getRequest.isLoading,
    savingData: putDataMutation.isLoading,
    getRequest,
    isOpen,
    onClose,
    onOpen,
    handleSave,
    handleOverwrite,
    handleReload,
    t,
  }
}
