import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { JustFoodContext } from '../../../store'
import { ISettings } from '../../types'

export default function useRestartButtonLogic() {
  const navigate = useNavigate()
  const { updateUserContext } = useContext(JustFoodContext)

  const handleRestart = async () => {
    const ctx: ISettings = {
      organizationType: undefined,
      link: undefined,
      password: undefined,
      code: undefined,
      areas: [],
    }
    updateUserContext(ctx)
    navigate('/', { replace: true })
  }
  return { handleRestart }
}
