import { IMeter, OrganizationType } from '../../types'

export const getTranslationStringForArea = (
  i: number,
  item: string,
  pageNumber: number,
  organizationType: OrganizationType | undefined
): string => {
  return `${organizationType}.area_${pageNumber}.question_${i + 1}.${item}`
}

// Translation configuration includes returnObjects and joinArray character ';'
export function parseMeters(translation: string): IMeter[] {
  if (translation.includes(';')) {
    const meters = translation.split(';')
    return meters.map((m) => {
      const value = m.split(',')
      return {
        text: value[0] ?? '',
        url: value[1] ?? '',
      }
    })
  }
  return []
}

/**
 * Parse links (text) (wrapped in <0></0>, <1></1> etc.) from translation strings.
 * Note: function does not actually check whether the content between aforementioned tags
 * is a valid link. Responsibility of that - as well as incorrectly formatted tags - is
 * left to the user.
 * To get right link to each element, each link should use different (sequential) numbers.
 *
 * @param translation
 * @returns Array of parsed texts
 */
export function parseLinks(translation: string): string[] {
  const regexp = /<\d>([^<>]*)<\/\d>/g
  let links: string[] = []
  const matches = translation.matchAll(regexp)

  for (let arr of [...matches]) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [match, g1] = arr
    links.push(g1)
  }
  return links
}
