import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { JustFoodContext } from '../../../store'
import { Route } from '../../../routes/types/routes'
import {
  IJustFoodGet,
  useJustFoodPasswordApiGet,
} from '../../justFoodApi/hooks/useJustFoodLogic'
import { ISettings } from '../../types'

export function useLinkPagePasswordLogic() {
  const { link } = useParams()
  const navigate = useNavigate()

  const { userContext, updateUserContext, resetUserContext } =
    useContext(JustFoodContext)

  const [passwordString, setPasswordString] = useState<string | null>(null)
  const [isError, setIsError] = useState(false)

  const [getPassword, setGetPassword] = useState<IJustFoodGet | null>(null)
  const getPasswordRequest = useJustFoodPasswordApiGet(getPassword)

  const handleContinue = () => {
    const data = { ...getPassword!, link: link }
    setGetPassword(data)
  }

  useEffect(() => {
    if (getPasswordRequest.isSuccess) {
      setGetPassword(null)
      setPasswordString(null)
      setIsError(false)
      // TODO: Validate data
      const data = getPasswordRequest.data
      const content: ISettings = JSON.parse(data.content!)
      const ctx: ISettings = {
        ...userContext,
        link: data.link,
        code: data.code,
        password: data.password || undefined,
        areas: content.areas,
        organizationType: content.organizationType,
        operator: content.operator,
        timeframe: content.timeframe,
        freecomment: content.freecomment,
      }
      updateUserContext(ctx)
      navigate(Route.MAIN_PAGE)
    } else if (getPasswordRequest.isError) {
      setGetPassword(null)
      setIsError(true)
      resetUserContext()
    }
  }, [
    getPasswordRequest,
    navigate,
    resetUserContext,
    updateUserContext,
    userContext,
    isError,
  ])

  return {
    isLoading: getPasswordRequest.isLoading,
    isError,
    setGetPassword,
    getPassword,
    passwordString,
    setPasswordString,
    handleContinue,
  }
}
