import { Image } from '@chakra-ui/react'
import DefaultImage from '../../../theme/images/area_default.png'
import AreaImage1 from '../../../theme/images/area_1.png'
import AreaImage2 from '../../../theme/images/area_2.png'
import AreaImage3 from '../../../theme/images/area_3.png'
import AreaImage4 from '../../../theme/images/area_4.png'
import AreaImage5 from '../../../theme/images/area_5.png'
import AreaImage6 from '../../../theme/images/area_6.png'
import AreaImage7 from '../../../theme/images/area_7.png'

export interface IAreaImage {
  page: number
  w: number | string
  alt: string
}

export default function AreaImage(props: IAreaImage) {
  switch (props.page) {
    case 1:
      return <Image {...props} src={AreaImage1} />
    case 2:
      return <Image {...props} src={AreaImage2} />
    case 3:
      return <Image {...props} src={AreaImage3} />
    case 4:
      return <Image {...props} src={AreaImage4} />
    case 5:
      return <Image {...props} src={AreaImage5} />
    case 6:
      return <Image {...props} src={AreaImage6} />
    case 7:
      return <Image {...props} src={AreaImage7} />
    default:
      return <Image {...props} src={DefaultImage} />
  }
}
