import { Box, Flex, Heading, Text } from '@chakra-ui/layout'
import { TFunction } from 'i18next'
import { CircleIcon } from '../../../theme/icons'
import { HighchartsLabelsWrapper } from './Styles'
import { ISummaryView } from './SummaryChart'

interface IHighchartsLabels {
  summary: ISummaryView
  t: TFunction
  gapSize: number
  isLargeView: boolean | undefined
}

export default function HighchartsLabels({
  summary,
  t,
  gapSize,
  isLargeView,
}: IHighchartsLabels) {
  return (
    <HighchartsLabelsWrapper>
      <Text fontWeight={'bold'} mb={5} alignContent="center">
        {t('summary.answers')} ({summary.totalAnswers} /{' '}
        {summary.totalQuestions})
      </Text>
      <Flex gap={gapSize}>
        <CircleIcon boxSize={5} color={'brand.ok'} />
        <Text>{summary.answerOk}</Text>
        <Text>{t('summary.answerok')}</Text>
      </Flex>
      <Flex gap={gapSize}>
        <CircleIcon boxSize={5} color={'brand.little'} />
        <Box>{summary.answerLittle}</Box>
        <Text>{t('summary.answerlittle')}</Text>
      </Flex>
      <Flex gap={gapSize}>
        <CircleIcon boxSize={5} color={'brand.alot'} />
        <Box>{summary.answerLot}</Box>
        <Text>{t('summary.answerlot')}</Text>
      </Flex>
      <Flex gap={gapSize}>
        <CircleIcon boxSize={5} color={'brand.palette5'} />
        <Box>{summary.answerNot}</Box>
        <Text>{t('summary.answernot')}</Text>
      </Flex>
      <Flex gap={gapSize}>
        <CircleIcon
          boxSize={5}
          color={'brand.palette4'}
          stroke={'brand.palette5'}
          strokeWidth={10}
        />
        <Box>{summary.notAnswered}</Box>
        <Text>{t('summary.notanswered')}</Text>
      </Flex>
      {summary.isFinished && isLargeView && (
        <Box>
          <Heading fontSize={'lg'} my={8}>
            {t('summary.questionnaireDone')}
          </Heading>
          <Text>{t('summary.questionnaireDoneDescription')}</Text>
        </Box>
      )}
    </HighchartsLabelsWrapper>
  )
}
