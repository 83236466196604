/* tslint:disable */
/* eslint-disable */
/**
 * JustFood API
 * <b>Edit this text in the swaggerDescription.html</b>  <br/>
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DeleteJustFoodCommand
 */
export interface DeleteJustFoodCommand {
  /**
   *
   * @type {string}
   * @memberof DeleteJustFoodCommand
   */
  password?: string | null
  /**
   *
   * @type {string}
   * @memberof DeleteJustFoodCommand
   */
  link?: string
}

export function DeleteJustFoodCommandFromJSON(
  json: any
): DeleteJustFoodCommand {
  return DeleteJustFoodCommandFromJSONTyped(json, false)
}

export function DeleteJustFoodCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteJustFoodCommand {
  if (json === undefined || json === null) {
    return json
  }
  return {
    password: !exists(json, 'password') ? undefined : json['password'],
    link: !exists(json, 'link') ? undefined : json['link'],
  }
}

export function DeleteJustFoodCommandToJSON(
  value?: DeleteJustFoodCommand | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    password: value.password,
    link: value.link,
  }
}
