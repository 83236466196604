import { JUSTFOOD_API_BASE_URL } from '../config'
import { Configuration, JustFoodsApi } from './justfood'

export default function useJustFoodApi() {
  const configuration = createConfiguration()

  return {
    justFoodApi: new JustFoodsApi(configuration),
  }
}

function createConfiguration() {
  const configuration = new Configuration({
    basePath: JUSTFOOD_API_BASE_URL,
    headers: {},
  })
  return configuration
}

export type IJustFoodApi = ReturnType<typeof useJustFoodApi>
