import {
  chakra,
  Box,
  IconProps,
  Icon,
  Text,
  Grid,
  Link,
  LinkProps,
  Flex,
  ButtonProps,
  Button,
  GridItem,
  ModalFooter,
} from '@chakra-ui/react'
import { Link as RRDLink, LinkProps as RRDLinkProps } from 'react-router-dom'
import { FaCheck } from 'react-icons/fa'

const RouterLink = (props: RRDLinkProps & LinkProps) => {
  return <Link as={RRDLink} {...props} />
}

export const StyledAreaLink = chakra(RouterLink, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
    position: 'relative',
    height: { base: '3rem', lg: '5.375rem' },
    pl: { base: '1.5rem', lg: '4rem' },
    pr: { base: '0.5rem', lg: '1.25rem' },
  },
})

export const AreaTitle = chakra(Text, {
  baseStyle: {
    fontWeight: 700,
    fontSize: { base: '1rem', lg: '1.25rem' },
    lineHeight: { base: '1.2rem', lg: '1.5rem' },
    color: 'white',
    whiteSpace: { base: 'nowrap', sm: 'normal' },
    textOverflow: { base: 'ellipsis', sm: 'inherit' },
    overflow: { base: 'hidden', sm: 'inherit' },
  },
})

export const AreaCompleteCheck = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    top: { base: '-0.5rem', lg: '-1.125rem' },
    left: { base: '-0.5rem', lg: '-1.125rem' },
    bg: 'white',
    borderRadius: 'full',
    h: { base: '1.75rem', lg: '3.25rem' },
    w: { base: '1.75rem', lg: '3.25rem' },
  },
})

export const AreaCompleteIcon = (props: IconProps) => {
  return (
    <Icon
      as={FaCheck}
      position="absolute"
      boxSize={{ base: '1rem', lg: '1.25rem' }}
      right={{ base: '0.35rem', lg: '0.70rem' }}
      bottom={{ base: '0.25rem', lg: '0.55rem' }}
      color="brand.palette15"
      {...props}
    />
  )
}

export const ActionButtonGrid = chakra(Grid, {
  baseStyle: {
    mt: { base: '1rem', md: '2rem', xl: '3rem' },
    mb: { base: '1rem', md: '1rem', xl: '2rem' },
    width: 'fit-content',
    ml: 'auto',
    gridTemplateColumns: {
      base: 'repeat(4, auto)',
      md: '1fr 1fr',
    },
    gridTemplateRows: 'auto',
    gridRowGap: { base: '0.75rem' },
    gridColumnGap: '1rem',
  },
})

export const HighchartsLabelsWrapper = chakra(Flex, {
  baseStyle: {
    width: 'fit-content',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
})

export const HighchartsGrid = chakra(Grid, {
  baseStyle: {
    mt: { lg: '-0.75rem' }, // This is used to align top of pie chart with area links
    mb: '2.375rem',
    px: { lg: '1rem' },
    gap: { base: '0.5rem', md: '1rem', lg: '2rem' },
    gridTemplateColumns: {
      base: '100%',
      sm: '60% 40%',
      md: '50% 50%',
      lg: '100%',
      xl: '100%',
    },
    justifyItems: { base: 'stretch' },
    alignItems: { base: 'top', sm: 'top', lg: 'center' },
    paddingTop: '1rem',
  },
})

export const ShareModalButton = (props: ButtonProps) => {
  const { ...rest } = props
  return (
    <Button
      fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: 'xl' }}
      _hover={{ bg: '#6a527a' }}
      {...rest}
    />
  )
}

export const LabelsGridItem = chakra(GridItem, {
  baseStyle: {
    mt: { sm: '1rem' }, //  This is used to align labels with top of pie chart
    display: 'flex',
    justifyContent: { base: 'center', md: 'flex-end' },
  },
})

export const ShareModalFooter = chakra(ModalFooter, {
  baseStyle: {
    flexDir: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    gap: { base: '1rem', md: '1.5rem' },
  },
})
