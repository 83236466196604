import { Box, Flex, Heading } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import HighChartsSummaryView, { ISummaryView } from './SummaryChart'
import { AreaLink } from './AreaLink'
import {
  HideOnMobile,
  InfoBar,
  Main,
  PageTemplate,
  SideContent,
} from '../../pageTemplate'
import { ActionButtons } from './ActionButtons'
import { useParseSnapshot } from '../hooks/useParseSnapshotLogic'
import OptionalInformationHeader from './OptionalInformationHeader'
import { CommentLink } from './CommentLink'
import { useContext } from 'react'
import { JustFoodContext } from '../../../store'
import useBackCheck from '../../../hooks/useBackCheck'

export default function MainView() {
  const { t } = useTranslation()
  const parsed = useParseSnapshot()
  const type = parsed.organization?.organizationType
  const { userContext } = useContext(JustFoodContext)
  useBackCheck()
  let ctx = { ...userContext }

  return (
    <PageTemplate>
      <InfoBar showBackgroundImage collapseOnMobile>
        <Box>
          <HideOnMobile>
            <Heading fontSize="lg" my={8}>
              {t(`${type}.mainpage.title`)}
            </Heading>
          </HideOnMobile>
          <Trans t={t}>{`${type}.mainpage.description`}</Trans>
        </Box>
      </InfoBar>
      <Main>
        <OptionalInformationHeader
          operator={parsed.operator}
          timeframe={parsed.timeframe}
        />
        <Flex direction="column" gap={5}>
          {parsed.organization?.areaQuestionCounts?.map((count, i) => {
            return (
              <AreaLink areaIndex={i} key={i} parsed={parsed} type={type} />
            )
          })}
          <CommentLink comment={ctx.freecomment} />
        </Flex>
        <ActionButtons />
      </Main>
      <SideContent showOnMobile>
        {parsed.totalAnswers > 0 && (
          <HighChartsSummaryView {...(parsed as ISummaryView)} />
        )}
      </SideContent>
    </PageTemplate>
  )
}
