import { Font, StyleSheet } from '@react-pdf/renderer'
import CenturyGothicBold from '../../../theme/fonts/CenturyGothicB.ttf'
import CenturyGothicItalic from '../../../theme/fonts/CenturyGothicI.ttf'

Font.register({
  family: 'CenturyGothic',
  fontWeight: 'normal',
  src: 'https://fonts.cdnfonts.com/s/18111/GOTHIC.woff',
})
Font.register({
  family: 'CenturyGothic',
  fontWeight: 'bold',
  src: CenturyGothicBold,
})
Font.register({
  family: 'CenturyGothic',
  fontWeight: 'normal',
  fontStyle: 'italic',
  src: CenturyGothicItalic,
})
Font.register({
  family: 'Janitor',
  src: 'https://fonts.cdnfonts.com/s/53673/Janitor.woff',
})

export const pdfStyles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20pt',
    gap: '20pt',
  },
  title: {
    fontSize: '22pt',
    marginBottom: '12pt',
    textAlign: 'center',
    color: '#483753',
    fontFamily: 'Janitor',
    fontWeight: 'normal',
  },
  subtitle: {
    fontSize: '12pt',
    fontWeight: 'bold',
  },
  justFoodIcon: {
    width: '65pt',
    height: '65pt',
  },
  detailsSection: {
    marginBottom: '12pt',
  },
  details: {
    fontSize: '10pt',
    marginBottom: '12pt',
  },
  detailsTitle: {
    fontWeight: 'bold',
  },
  summaryTitle: {
    fontWeight: 'bold',
  },
  summary: {
    width: '100%',
    fontSize: '10pt',
  },
  summaryOptionsWrapper: {
    marginTop: '8pt',
    marginBottom: '20pt',
  },
  summaryOption: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4pt',
    alignItems: 'center',
    marginBottom: '8pt',
  },
  page: {
    paddingVertical: '1.5cm',
    paddingHorizontal: '1.5cm',
    fontFamily: 'CenturyGothic',
    fontSize: '12pt',
  },
  pageFooter: {
    fontSize: '8pt',
    bottom: '-0.5cm',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  footerTitle: {
    position: 'absolute',
    right: 0,
  },
  singleArea: {
    marginBottom: '20pt',
  },
  areaImage: {
    height: '20pt',
    width: 'auto',
    marginRight: '11pt',
    marginLeft: '15pt',
  },
  areaTitle: {
    fontSize: '11pt',
    fontWeight: 'bold',
  },
  singleQuestion: {
    marginVertical: '16pt',
    paddingLeft: '1.5cm',
    paddingRight: '1cm',
  },
  questionText: {
    fontSize: '10pt',
    marginBottom: '8pt',
    fontWeight: 'bold',
  },
  answerContainer: {
    marginLeft: '12pt', // indent answer
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  answerText: {
    fontSize: '9pt',
  },
  missingAnswer: {
    fontSize: '9pt',
    fontStyle: 'italic',
  },
  commentContainer: {
    fontSize: '9pt',
    marginTop: '8pt',
    marginLeft: '34pt', // 12 + 12 + 10, aka answer indent, option width and mr
  },
  commentLead: {
    fontWeight: 'bold',
  },
})
