import {
  ApiJustFoodsLinkDeleteRequest,
  ApiJustFoodsLinkGetRequest,
  ApiJustFoodsLinkPasswordGetRequest,
  ApiJustFoodsLinkPutRequest,
  ApiJustFoodsPostRequest,
  JustFoodDTO,
} from '../../../api/justfood'
import { IJustFoodApi } from '../../../api/useJustFoodApi'

export async function getJustFood(
  { justFoodApi }: IJustFoodApi,
  request: ApiJustFoodsLinkGetRequest
): Promise<JustFoodDTO> {
  const response = await justFoodApi.apiJustFoodsLinkGet(request)
  return response
}

export async function getJustFoodPassword(
  { justFoodApi }: IJustFoodApi,
  request: ApiJustFoodsLinkPasswordGetRequest
): Promise<JustFoodDTO> {
  const response = await justFoodApi.apiJustFoodsLinkPasswordGet(request)
  return response
}

export async function postJustFood(
  { justFoodApi }: IJustFoodApi,
  request: ApiJustFoodsPostRequest
) {
  const response = await justFoodApi.apiJustFoodsPost(request)
  return response
}

export async function putJustFood(
  { justFoodApi }: IJustFoodApi,
  request: ApiJustFoodsLinkPutRequest
) {
  const response = await justFoodApi.apiJustFoodsLinkPut(request)
  return response
}

export async function deleteJustFood(
  { justFoodApi }: IJustFoodApi,
  request: ApiJustFoodsLinkDeleteRequest
) {
  const response = await justFoodApi.apiJustFoodsLinkDelete(request)
  return response
}
