import {
  PageTemplateView,
  IPageTemplateProps,
} from './components/PageTemplateView'

export function PageTemplate(props: IPageTemplateProps) {
  return <PageTemplateView {...props} />
}

export { SideContent } from './components/SideContent'
export { InfoBar } from './components/Infobar'
export { Main, HideOnMobile } from './components/Styles'
