import {
  Box,
  Button,
  Center,
  Heading,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import { AppRoute } from '../../../routes'
import { useTranslation } from 'react-i18next'
import { useLinkPageLogic } from '../hooks/useLinkPageLogic'
import { InfoBar, Main, PageTemplate, SideContent } from '../../pageTemplate'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export default function LinkPageView({
  isSuccess,
  isLoading,
}: ReturnType<typeof useLinkPageLogic>) {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <Center mt={100}>
        <Spinner />
      </Center>
    )
  }

  if (isSuccess) {
    return (
      <PageTemplate>
        <InfoBar showBackgroundImage />
        <Main>
          {isSuccess ? (
            <VStack
              minH="75%"
              alignItems={{ base: 'baseline' }}
              justifyContent="center"
              px="1rem"
              gap={30}
            >
              <Heading>{t('linkpage.title')}</Heading>
              <Text>{t('linkpage.description')}</Text>
              <Box>
                <Button as={ReactLink} to={AppRoute.MAIN_PAGE}>
                  {t('linkpage.continue')}
                </Button>
              </Box>
              <ReactLink to="/" color="brand.palette7" target="_blank">
                <Text as="b">
                  {t('linkpage.readmore')} <ExternalLinkIcon />
                </Text>
              </ReactLink>
            </VStack>
          ) : (
            <Box textAlign={'center'} mt={100} w={'full'}>
              <Box>{t('linkpage.checkcode')}</Box>
              <Button as={ReactLink} to={AppRoute.FRONT_PAGE}>
                {t('linkpage.frontpage')}
              </Button>
            </Box>
          )}
        </Main>
        <SideContent />
      </PageTemplate>
    )
  }

  // Invalid data
  return (
    <Box textAlign={'center'} mt={100} w={'full'}>
      <Box>{t('linkpage.checkcode')}</Box>
      <Button variant={'common'} as={ReactLink} to={AppRoute.FRONT_PAGE}>
        {t('linkpage.frontpage')}
      </Button>
    </Box>
  )
}
