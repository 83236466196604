import { InfoIcon } from '@chakra-ui/icons'
import { Flex, Link, Spacer, Text } from '@chakra-ui/layout'
import { RadioGroup } from '@chakra-ui/radio'
import {
  Accordion,
  AccordionItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { TFunction } from 'i18next'
import { ISettings, OptionType, OrganizationType } from '../../types'
import { getTranslationStringForArea, parseLinks } from '../utils'
import { CheckmarkRadio } from '../../../components/checkmarkRadio'
import { AutoResizeTextarea } from './AutoResizeTextarea'
import useMobileLayout from '../../../hooks/useMobileLayout'
import {
  StyledAccordionButton,
  AccordionHeading,
  StyledAccordionPanel,
} from '../../pageTemplate/components/Styles'
import { Trans } from 'react-i18next'
import { QuestionWrapper } from './Styles'

export default function QuestionAreaView({
  t,
  i,
  userContext,
  areaIndex,
  pageNumber,
  organizationType,
  onChange,
  updateUserContext,
}: {
  t: TFunction
  i: number
  userContext: ISettings
  areaIndex: number
  pageNumber: number
  organizationType: OrganizationType | undefined
  onChange: (i: number, comment: string) => void
  updateUserContext: (ctx: ISettings) => void
}) {
  const isMobile = useMobileLayout()
  const title = getTranslationStringForArea(
    i,
    'title',
    pageNumber,
    organizationType
  )
  const label = getTranslationStringForArea(
    i,
    'infotext',
    pageNumber,
    organizationType
  )
  const infotext = t(label)
  const infoLinks = parseLinks(infotext)

  const okText = getTranslationStringForArea(
    i,
    OptionType.OK,
    pageNumber,
    organizationType
  )
  const littleText = getTranslationStringForArea(
    i,
    OptionType.LITTLE_TO_IMPROVE,
    pageNumber,
    organizationType
  )
  const lotText = getTranslationStringForArea(
    i,
    OptionType.A_LOT_TO_IMPROVE,
    pageNumber,
    organizationType
  )
  const notText = getTranslationStringForArea(
    i,
    OptionType.NOT_APPLICABLE,
    pageNumber,
    organizationType
  )
  const addCommentPlaceholder = t('areapage.addCommentPlaceholder')

  return (
    <QuestionWrapper key={i}>
      {/* Question title and infotext */}
      {isMobile ? (
        infotext ? (
          <Accordion allowToggle variant="minimal" w="100%">
            <AccordionItem>
              <StyledAccordionButton px={0}>
                <AccordionHeading>
                  {i + 1}. {t(title)}
                </AccordionHeading>
                <InfoIcon />
              </StyledAccordionButton>
              <StyledAccordionPanel>
                <Trans i18nKey={label}>
                  {infoLinks &&
                    infoLinks.map((link) => (
                      <Link href={link} isExternal key={link} />
                    ))}
                </Trans>
              </StyledAccordionPanel>
            </AccordionItem>
          </Accordion>
        ) : (
          <AccordionHeading mb={2}>
            {i + 1}. {t(title)}
          </AccordionHeading>
        )
      ) : (
        <Flex>
          <Text fontWeight={'bold'} mb={2}>
            {i + 1}. {t(title)}
          </Text>
          <Spacer />
          {infotext && (
            <Popover placement="left-start">
              <PopoverTrigger>
                <InfoIcon color={'brand.palette1'} tabIndex={0} ml={2} />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow bg="brand.palette1" />
                <PopoverCloseButton />
                <PopoverBody>
                  <Trans i18nKey={label}>
                    {infoLinks &&
                      infoLinks.map((link) => (
                        <Link href={link} isExternal key={link} />
                      ))}
                  </Trans>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
        </Flex>
      )}
      {/* Questions */}
      <RadioGroup
        key={i}
        onChange={(value) => {
          let ctx = { ...userContext }
          ctx.areas[areaIndex].answers[i].option = value as OptionType
          updateUserContext(ctx)
        }}
        value={`${userContext.areas?.at(areaIndex)?.answers?.at(i)?.option}`}
        mb={4}
        ml={{ base: 0, md: '1.1rem' }}
      >
        <Flex direction="column" gap="0.625rem">
          <CheckmarkRadio value={`${OptionType.OK}`} variant="ok">
            {t(okText)}
          </CheckmarkRadio>
          <CheckmarkRadio
            value={`${OptionType.LITTLE_TO_IMPROVE}`}
            variant="little"
          >
            {t(littleText)}
          </CheckmarkRadio>
          <CheckmarkRadio
            value={`${OptionType.A_LOT_TO_IMPROVE}`}
            variant="lot"
          >
            {t(lotText)}
          </CheckmarkRadio>
          <CheckmarkRadio value={`${OptionType.NOT_APPLICABLE}`} variant="not">
            {t(notText)}
          </CheckmarkRadio>
        </Flex>
      </RadioGroup>
      <AutoResizeTextarea
        mr={10}
        placeholder={addCommentPlaceholder}
        value={userContext.areas.at(areaIndex)?.answers.at(i)?.comment ?? ''}
        onChange={(event) => {
          onChange(i, event.target.value)
        }}
        maxLength={500}
      />
    </QuestionWrapper>
  )
}
