import { useToast, UseToastOptions } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function (): (
  toastId: string,
  status: UseToastOptions['status'],
  titleKey: string,
  descKey?: string
) => void {
  const [showToast, setShowToast] = useState<{
    value: boolean
    toastId?: string
    status?: UseToastOptions['status']
    titleKey?: string
    descKey?: string
  }>({
    value: false,
    toastId: undefined,
    status: 'info',
    titleKey: undefined,
    descKey: undefined,
  })

  const { t } = useTranslation()
  const toast = useToast()

  useEffect(() => {
    if (
      showToast.toastId &&
      !toast.isActive(showToast.toastId) &&
      showToast.titleKey
    ) {
      toast({
        id: showToast.toastId,
        title: t(showToast.titleKey),
        description: showToast.descKey ? t(showToast.descKey) : undefined,
        status: showToast.status,
        duration:
          showToast.status === 'error' || showToast.status === 'warning'
            ? null
            : 4000,
        position: 'top',
        isClosable: true,
      })
    }
  }, [showToast, t, toast])

  return (
    toastId: string,
    status: UseToastOptions['status'],
    titleKey: string,
    descKey?: string
  ) => {
    setShowToast({ value: true, toastId, status, titleKey, descKey })
  }
}
