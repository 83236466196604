import { Flex, GridItem, useToken } from '@chakra-ui/react'
import Highcharts from 'highcharts'
import highchartsAccessibility from 'highcharts/modules/accessibility'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next'
import { HighchartsGrid, LabelsGridItem } from './Styles'
import useLayout from '../../../hooks/useLayout'
import HighchartsLabels from './HighchartsLabels'
import './highcharts.css'

export interface ISummaryView {
  answerOk: number
  answerLittle: number
  answerLot: number
  answerNot: number
  notAnswered: number
  totalAnswers: number
  totalQuestions: number
  isFinished: boolean
}

export default function SummaryChart(summary: ISummaryView) {
  const { t } = useTranslation()
  const isLargeView = useLayout()
  highchartsAccessibility(Highcharts)
  const [ok, little, alot, not, unanswered, border] = useToken('colors', [
    'brand.ok',
    'brand.little',
    'brand.alot',
    'brand.palette5',
    'brand.palette4',
    'brand.palette5',
  ])

  const data = [
    { name: t('summary.answerok'), y: summary.answerOk, color: ok },
    {
      name: t('summary.answerlittle'),
      y: summary.answerLittle,
      color: little,
    },
    { name: t('summary.answerlot'), y: summary.answerLot, color: alot },
    { name: t('summary.answernot'), y: summary.answerNot, color: not },
    {
      name: t('summary.notanswered'),
      y: summary.notAnswered,
      color: unanswered,
    },
  ]

  const options = {
    chart: {
      type: 'pie',
      style: {
        maxHeight: '320px',
      },
      height: '100%',
    },
    plotOptions: {
      series: {
        borderWidth: 2,
        borderColor: border,
      },
    },
    title: { text: '' },
    series: [
      {
        name: '',
        data: data,
        size: '100%',
        innerSize: '50%',
        dataLabels: [],
        legend: {
          enabled: false,
        },
      },
    ],
    credits: {
      enabled: true,
      href: '',
      style: {
        fontSize: isLargeView ? '1.15rem' : '0.85rem',
      },
    },
  }

  return (
    <>
      {isLargeView && (
        <HighchartsGrid>
          <HighchartsReact highcharts={Highcharts} options={options} />
          <Flex justifyContent="center">
            <HighchartsLabels
              summary={summary}
              t={t}
              gapSize={3}
              isLargeView={isLargeView}
            />
          </Flex>
        </HighchartsGrid>
      )}
      {!isLargeView && (
        <HighchartsGrid px="1rem">
          <LabelsGridItem>
            <HighchartsLabels
              summary={summary}
              t={t}
              gapSize={1.5}
              isLargeView={isLargeView}
            />
          </LabelsGridItem>
          <GridItem>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </GridItem>
        </HighchartsGrid>
      )}
    </>
  )
}
